
import { defineComponent } from 'vue';
import { Fragment } from 'vue-fragment';

/* This component is only used for input type password */
export default defineComponent({
	name: 'HokInputWrapper',
	components: { Fragment }, // fragment only necessary for vue2, as it's built into vue3
	props: {
		wrap: { type: Boolean, default: false },
		focused: { type: Boolean, default: false },
		value: { type: [String, Number], default: '' }
	}
});
