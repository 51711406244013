var render, staticRenderFns
import script from "./HokIcon.vue?vue&type=script&lang=ts"
export * from "./HokIcon.vue?vue&type=script&lang=ts"
import style0 from "./HokIcon.vue?vue&type=style&index=0&id=5f85b831&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_webpack@4.47.0__css-loader@5.2.7_webpack@4.47.0__vue-te_eoxntyycrqiuiceqfmr2hlaiyy/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f85b831",
  null
  
)

export default component.exports