/* eslint-disable prefer-rest-params,no-sequences,prefer-destructuring,no-multi-assign */
// localStorage.setItem('debugTracking', 'true');
import { EventBus } from '@hokify/shared-components/lib/eventbus';
import { lsTest } from '@hokify/shared-components/lib/helpers/localstorage';
import { genCookie, getCookie, setCookie } from '@hokify/shared-components/lib/helpers/cookie';
import type { ICheckoutItem } from '@hokify/shared-components/lib/types/checkout';

import type { APITypeObjectId, APIObjectType, IAPIShoppingCartItem } from '@hokify/common';
import type { Context } from '@nuxt/types';
import type {
	IRecruiterTrackingOptions,
	TrackingActions,
	TrackingRelation,
	IJobSeekerTrackingOptions,
	IWebsiteTrackingOptions,
	ITrackWebsiteEvents,
	ITrackUserEvents,
	ITrackCompanyEvents,
	ITrackGenericEvents,
	B2BPerformanceStatsAction
} from './types';

function hasRelations(obj: any): obj is { relations } {
	return obj && obj.relations !== undefined;
}

function hasJob(obj: any): obj is { job: { _id: APITypeObjectId<APIObjectType.Job> } } {
	return obj && obj.job !== undefined;
}

function hasCompany(obj: any): obj is { company: { _id: APITypeObjectId<APIObjectType.Company> } } {
	return obj && obj.company !== undefined;
}

export type PrivacyOptions = 'essential' | 'externalYoutube' | 'ads' | 'stats';

let acceptedTracking = false;

let gaCID: string | undefined | null;
let waitForGa4Promise: Promise<void> | undefined;
async function waitForGA4Init() {
	if (!process.env.cordova && process.client) {
		try {
			if (!waitForGa4Promise) {
				waitForGa4Promise = Promise.race([
					new Promise<void>(resolve => {
						if (gtagAvailable(window)) {
							resolve();
							return;
						}
						EventBus().$on('gtag-initialized', resolve);
					}),
					// resolve after 10 seconds
					new Promise<void>((_resolve, reject) => {
						setTimeout(() => {
							reject(new Error('ga4 initialization timed out'));
						}, 10000);
					})
				]);
			}
			return await waitForGa4Promise;
		} catch (err) {
			console.error('cannot initialize ga4', err);
		}
	}
}

function gtagAvailable(window: any): window is { gtag: any } {
	return typeof window.gtag !== 'undefined' && window.gtag;
}

export async function getGoogleAnalyticsClientId(): Promise<string | undefined> {
	if (gaCID !== undefined) {
		return gaCID || undefined;
	}

	// ensure we have a legit chance to get a gaCID
	await waitForGA4Init();

	if (process.client && gtagAvailable(window)) {
		try {
			gaCID = await Promise.race([
				new Promise<string>(resolve => {
					window.gtag('get', process.env.gaTracking, 'client_id', clientId => {
						resolve(clientId);
					});
				}),
				// resolve after 2 seconds, so that we do not wait forever for a client id
				new Promise<null>((_resolve, reject) => {
					setTimeout(() => {
						// ga4 client_id timed out
						reject(new Error('ga 4 client_id timed out'));
					}, 2000);
				})
			]);
		} catch (err) {
			console.error('ga4 client_id error', err);
			// set to null, so we do not retry it
			gaCID = null;
		}
	}
	return gaCID || undefined;
}

const isPWA = process.env.mode === 'b2c' || process.env.mode === 'b2b';

const enabledTrackings = {
	facebook: false,
	google: false,
	tagmanager: false,
	criteo: false,
	rtbhouse: false,
	tiktok: false,
	snapchat: false
};

const enum GoogleRemarketingPageType {
	ViewContent = 'offerdetail',
	ListView = 'searchresults'
}

/**
 * as long as acceptedTracking wasn't called we put everything in the trackingQueue and process the
 * events when the event has been called
 */

const trackingQueue: (
	| { type: 'google'; data: Parameters<typeof __sendGAEvent> }
	| { type: 'facebook'; data: Parameters<typeof __sendFbEvent> }
	| { type: 'tagmanager'; data: Parameters<typeof __sendToTagManager> }
	| { type: 'criteo'; data: Parameters<typeof __sendCriteoEvent> }
	| { type: 'rtbhouse'; data: Parameters<typeof __sendRTBHouseEvent> }
	| { type: 'tiktok'; data: Parameters<typeof __sendTiktokEvent> }
	| { type: 'snapchat'; data: Parameters<typeof __sendSnapchatEvent> }
	| { type: 'recruiterTracking'; data: Parameters<typeof __trackRecruiter> }
)[] = [];

const FacebookConfig = {
	jobseeker: {
		pixelId: '1710086339221505',
		appId: '1031146320229277'
	},
	company: {
		pixelId: '459573094404807',
		appId: '152321618801150'
	}
};

export const FacebookEvent: Record<string, string> = {
	AddPaymentInfo: 'AddPaymentInfo',
	AddToCart: 'AddToCart',
	ViewContent: 'ViewContent',
	CompleteRegistration: 'CompleteRegistration',
	InitiateCheckout: 'InitiateCheckout',
	Purchase: 'Purchase',
	LevelAchieved: 'LevelAchieved',
	SpentCredits: 'SpentCredits',
	Search: 'Search'
};

export const CriteoEventTags: Record<string, string> = {
	List: 'viewList',
	Product: 'viewItem',
	Cart: 'addToCart',
	Basket: 'viewBasket',
	Sales: 'trackTransaction'
};

export const RTBHouseEventTags: Record<string, string> = {
	List: 'listing',
	Category: 'category',
	Product: 'offer',
	Wishlist: 'wishlist',
	StartOrder: 'startorder',
	Conversion: 'conversion'
};

export const TiktokEventTags: Record<string, string> = {
	Search: 'Search',
	ViewContent: 'ViewContent',
	CompleteRegistration: 'CompleteRegistration',
	AddToCart: 'AddToCart',
	InitiateCheckout: 'InitiateCheckout',
	CompletePayment: 'CompletePayment'
};

export const SnapchatEventTags: Record<string, string> = {
	ListView: 'LIST_VIEW',
	ViewContent: 'VIEW_CONTENT',
	SignUp: 'SIGN_UP',
	AddToCart: 'ADD_CART',
	StartCheckout: 'START_CHECKOUT',
	Purchase: 'PURCHASE'
};

export const trackingConfigDomain = {
	at: {
		criteoTracker: 100255,
		rtbHouseTracker: 'G4amMH2vyHpIGLEycOWE'
	},
	de: {
		criteoTracker: 100254,
		rtbHouseTracker: 'd7TnUk8f3LvBaSyUR1jR'
	},
	ch: {
		criteoTracker: 101316
	}
};

declare global {
	interface Window {
		acceptedTracking?: () => void;
		hasInteracted?: () => void;

		// google
		GoogleAnalyticsObject?: any;
		gTagManagerDataLayer?: any[];
		gtag?: any;
		dataLayer?: any;

		// facebook
		fbq?: any;
		_fbq: any;

		// hotjar
		hj?: any;

		// criteo
		criteo_q?: any;

		// rtbHouse
		rtbhEvents?: any;

		// tiktok
		TiktokAnalyticsObject?: any;
		ttq?: any;

		// snapchat
		snaptr?: any;
	}
}

let cachedHashes: Record<string, string>;
export async function createHashedValue(value: string | undefined) {
	if (!value) {
		return;
	}

	// caching
	if (cachedHashes && cachedHashes[value]) {
		return cachedHashes[value];
	}

	function hex(hashBuffer: ArrayBuffer): string {
		const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
		return hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
	}

	async function digestMessage(message): Promise<string> {
		const encoder = new TextEncoder();
		const data = encoder.encode(message);
		return hex(await crypto?.subtle?.digest('SHA-256', data));
	}

	try {
		const hashedEmail = await digestMessage(value.replace(/\s+/g, '').toLowerCase());

		// for caching
		if (!cachedHashes) {
			cachedHashes = {};
		}
		cachedHashes[value] = hashedEmail;

		return hashedEmail;
	} catch (error: any) {
		console.warn('hashing failed', error);
	}
}

export function getBrowserType(): string {
	try {
		const ua = navigator.userAgent || navigator.vendor || (window as any).opera;
		if (ua.includes('FBAN') || ua.includes('FBAV') || ua.includes('Facebook')) {
			return 'Facebook';
		}

		if (ua.includes('Instagram')) {
			return 'Instagram';
		}

		if (ua.includes('ByteLo') || ua.includes('Bytedance') || ua.includes('TikTok')) {
			return 'TikTok';
		}

		return 'Regular';
	} catch (err) {
		console.error('getBrowserType failed', err);
		return 'Unknown';
	}
}

export function __importFacebookSDK() {
	// only load facebook pixel if not already loaded
	if (typeof window.fbq === 'undefined' || !window.fbq) {
		// eslint-disable-next-line func-names
		(function (f: Window, b: Document, e: string, v: string, n?: any, t?: any, s?: any) {
			if (f.fbq) {
				return;
			}
			// eslint-disable-next-line func-names,no-multi-assign
			n = f.fbq = function () {
				// eslint-disable-next-line @typescript-eslint/no-unused-expressions,prefer-rest-params
				n.callMethod ? n.callMethod(...arguments) : n.queue.push(arguments);
			};
			if (!f._fbq) {
				f._fbq = n;
			}
			n.push = n;
			n.loaded = true;
			n.version = '2.0';
			n.queue = [];
			t = b.createElement(e) as HTMLScriptElement;
			t.async = true;
			t.src = v;
			// eslint-disable-next-line prefer-destructuring
			s = b.getElementsByTagName(e)[0];
			s.parentNode.insertBefore(t, s);
		})(window, document, 'script', 'https://connect.facebook.net/de_AT/fbevents.js');
		window.fbq('set', 'autoConfig', false, FacebookConfig.jobseeker.pixelId);
		window.fbq('set', 'autoConfig', false, FacebookConfig.company.pixelId);

		window.fbq('init', FacebookConfig.jobseeker.pixelId); // b2c
		window.fbq('init', FacebookConfig.company.pixelId); // b2b

		if (process.env.cordova) {
			window.fbq(
				'set',
				'mobileBridge',
				FacebookConfig.jobseeker.pixelId,
				FacebookConfig.jobseeker.appId
			); // b2c
			window.fbq(
				'set',
				'mobileBridge',
				FacebookConfig.company.pixelId,
				FacebookConfig.company.appId
			); // b2b
		}
	} else {
		// make sure consent is granted, if fb pixel is already loaded
		window.fbq('consent', 'grant');
	}
}

export function __importGTag() {
	const analyticsJS = `https://analytics.hokify.com/gtag/js?id=${process.env.gaTracking}`;
	window.dataLayer = window.dataLayer || [];

	(function loadJs(jsFile: string) {
		if (window.GoogleAnalyticsObject) {
			return;
		}
		window.GoogleAnalyticsObject = 'gtag';
		window.gtag = function gtag() {
			window.dataLayer.push(arguments);
		};

		const scriptTag = document.createElement('script') as HTMLScriptElement;
		const firstScriptTag = document.getElementsByTagName('script')[0];
		scriptTag.async = true;
		scriptTag.src = jsFile;
		if (firstScriptTag.parentNode) {
			firstScriptTag.parentNode.insertBefore(scriptTag, firstScriptTag);
		}
	})(analyticsJS);

	// make sure consent is denied per default
	// https://developers.google.com/tag-platform/devguides/consent#implementation_example
	window.gtag('consent', 'default', {
		analytics_storage: 'denied',
		ad_storage: 'denied',
		ad_user_data: 'denied',
		ad_personalization: 'denied'
	});

	window.gtag('js', new Date());

	const debug_mode = (lsTest() && localStorage.getItem('debugTracking')) || undefined;

	const browserType = getBrowserType();

	const gaConfig = {
		transport_url: 'https://analytics.hokify.com/',
		first_party_collection: true,
		debug_mode,
		browser_type: browserType,
		app_name: process.env.appType || 'hokify',
		app_version: process.env.version,
		send_page_view: false
	};

	// GA4 INIT TRACKER (default)
	window.gtag('config', process.env.gaTracking, gaConfig);

	// logging to console
	if (lsTest() && localStorage.getItem('debugTracking')) {
		console.log(
			'%cBrowser Type:',
			'color: white; background: #D3D3D3; font-size: 11px; font-weight: bold; border-radius: 5px; padding: 5px 10px; margin: 5px 0px;',
			browserType
		);
		console.log(
			'%c GA4 INIT TRACKER (default)',
			'color: white; background: orange; font-size: 11px; font-weight: bold; border-radius: 5px; padding: 5px 10px; margin: 5px 0px;',
			process.env.gaTracking
		);
	}

	// save gaCID to variable
	window.gtag('get', process.env.gaTracking, 'client_id', clientId => {
		gaCID = clientId;
	});

	// https://developers.google.com/analytics/devguides/collection/ga4/user-id?platform=websites
	EventBus().$on('logged-in', ({ user }) => {
		if (gtagAvailable(window) && user?._id && enabledTrackings.google) {
			window.gtag('set', 'user_id', user._id);
		}
	});

	EventBus().$emit('gtag-initialized');
}

export function __importTagManager() {
	if (window.gTagManagerDataLayer) {
		// if tagManager is already available, skip this part here
		return;
	}
	// google tag manager
	window.gTagManagerDataLayer = window.gTagManagerDataLayer || [];
	// eslint-disable-next-line func-names,no-unused-expressions
	(function (w: Window, d: Document, s: string, l: string, i: string) {
		w[l] = w[l] || [];
		w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
		const f = d.getElementsByTagName(s)[0];
		const j = d.createElement(s) as HTMLScriptElement;
		const dl = l !== 'dataLayer' ? `&l=${l}` : '';
		j.async = true;
		j.src = `https://analytics.hokify.com/gtm.js?id=${i}${dl}`;
		if (f.parentNode) {
			f.parentNode.insertBefore(j, f);
		}
	})(window, document, 'script', 'gTagManagerDataLayer', 'GTM-PJXX8S3');
}

export function __importCriteoScript(criteoTracker) {
	if (window.criteo_q) {
		// if criteo is already available, skip this part here
		return;
	}
	// criteo
	window.criteo_q = window.criteo_q || [];
	// eslint-disable-next-line func-names,no-unused-expressions
	(function (d: Document, s: string, a: string) {
		const f = d.getElementsByTagName(s)[0];
		const j = d.createElement(s) as HTMLScriptElement;
		j.async = true;
		j.src = `//dynamic.criteo.com/js/ld/ld.js?a=${a}`;
		if (f.parentNode) {
			f.parentNode.insertBefore(j, f);
		}
	})(document, 'script', criteoTracker);
}

export function __importRTBHouseScript(rtbHouseTracker) {
	if (window.rtbhEvents) {
		return;
	}
	window.rtbhEvents = window.rtbhEvents || [];
	// eslint-disable-next-line func-names
	(function (w, d, dn, t) {
		w[dn] = w[dn] || [];
		w[dn].push({ eventType: 'init', value: t, dc: '' });
		const f = d.getElementsByTagName('script')[0];
		const c = d.createElement('script');
		c.async = true;
		c.src = `https://tags.creativecdn.com/${t}.js`;
		if (f.parentNode) {
			f.parentNode.insertBefore(c, f);
		}
	})(window, document, 'rtbhEvents', rtbHouseTracker);
}

export function __importTiktokScript() {
	if (window.ttq) {
		return;
	}
	window.ttq = window.ttq || [];

	// eslint-disable-next-line func-names
	(function (w, d, t) {
		w.TiktokAnalyticsObject = t;
		const ttq = (w[t] = w[t] || []);
		// eslint-disable-next-line @typescript-eslint/no-unused-expressions
		(ttq.methods = [
			'page',
			'track',
			'identify',
			'instances',
			'debug',
			'on',
			'off',
			'once',
			'ready',
			'alias',
			'group',
			'enableCookie',
			'disableCookie'
		]),
			// eslint-disable-next-line @typescript-eslint/no-shadow
			(ttq.setAndDefer = function (t, e) {
				t[e] = function () {
					t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
				};
			});
		for (let i = 0; i < ttq.methods.length; i++) ttq.setAndDefer(ttq, ttq.methods[i]);
		// eslint-disable-next-line @typescript-eslint/no-shadow,@typescript-eslint/no-unused-expressions
		(ttq.instance = function (t) {
			// eslint-disable-next-line no-var,vars-on-top
			for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++)
				ttq.setAndDefer(e, ttq.methods[n]);
			// eslint-disable-next-line block-scoped-var
			return e;
		}),
			(ttq.load = function (e, n) {
				const i = 'https://analytics.tiktok.com/i18n/pixel/events.js';
				// eslint-disable-next-line @typescript-eslint/no-unused-expressions
				(ttq._i = ttq._i || {}),
					(ttq._i[e] = []),
					(ttq._i[e]._u = i),
					(ttq._t = ttq._t || {}),
					(ttq._t[e] = +new Date()),
					(ttq._o = ttq._o || {}),
					(ttq._o[e] = n || {});
				const o = d.createElement('script');
				// eslint-disable-next-line @typescript-eslint/no-unused-expressions
				(o.type = 'text/javascript'), (o.async = !0), (o.src = `${i}?sdkid=${e}&lib=${t}`);
				const a = d.getElementsByTagName('script')[0];
				a.parentNode?.insertBefore(o, a);
			});

		ttq.load('CI8OFQJC77U4TTM9HQKG');
		ttq.page();
	})(window, document, 'ttq');
}

export function __importSnapchatScript() {
	// eslint-disable-next-line func-names
	(function (e, t, n) {
		if (e.snaptr) return;
		// eslint-disable-next-line no-var,vars-on-top,func-names
		const a: any = (e.snaptr = function () {
			// eslint-disable-next-line @typescript-eslint/no-unused-expressions, prefer-spread
			a.handleRequest ? a.handleRequest.apply(a, arguments) : a.queue.push(arguments);
		});
		a.queue = [];
		const s = 'script';
		const r = t.createElement(s);
		r.async = !0;
		r.src = n;
		const u = t.getElementsByTagName(s)[0];
		u.parentNode?.insertBefore(r, u);
	})(window, document, 'https://sc-static.net/scevent.min.js');
}

export function __unloadCriteo() {
	const scripts = document.getElementsByTagName('script');
	if (scripts) {
		Array.from(scripts).forEach(script => {
			if (script.getAttribute('src')?.includes('criteo.com')) {
				script.parentNode?.removeChild(script);
			}
		});
	}
}

export function __unloadRTBHouse() {
	const scripts = document.getElementsByTagName('script');
	if (scripts) {
		Array.from(scripts).forEach(script => {
			if (script.getAttribute('src')?.includes('tags.creativecdn.com')) {
				script.parentNode?.removeChild(script);
			}
		});
	}
}

export function __unloadTiktok() {
	const scripts = document.getElementsByTagName('script');
	if (scripts) {
		Array.from(scripts).forEach(script => {
			if (script.getAttribute('src')?.includes('analytics.tiktok.com')) {
				script.parentNode?.removeChild(script);
			}
		});
	}
}

export function __unloadSnapchat() {
	const scripts = document.getElementsByTagName('script');
	if (scripts) {
		Array.from(scripts).forEach(script => {
			if (script.getAttribute('src')?.includes('sc-static.net')) {
				script.parentNode?.removeChild(script);
			}
		});
	}
}

export async function __sendToTagManager(params) {
	if (!acceptedTracking) {
		trackingQueue.push({ type: 'tagmanager', data: [params] });
		if (lsTest() && localStorage.getItem('debugTracking')) {
			console.log(
				'%cTracking event pushed to queue: tagmanager',
				'color: white; background: #D3D3D3; font-size: 11px; font-weight: bold; border-radius: 5px; padding: 5px 10px; margin: 5px 0px;',
				params
			);
		}
		return;
	}

	let sent = false;

	if (enabledTrackings.tagmanager) {
		// this call is only here for safety,
		// as we initialize the tag manager already on acceptedTracking()
		// but only in the pwa, not in the website
		// in case we merge the code base, we need this additional call here to
		// load the tag manager "on demand"
		__importTagManager();

		try {
			const acceptedCookies = lsTest() && localStorage.getItem('accept-cookies');
			params.acceptedPrivacies = (acceptedCookies && JSON.parse(acceptedCookies)) || [];
		} catch (err) {
			console.log('acceptedPrivacies gtag parsing failed', err);
		}

		if (window.gTagManagerDataLayer) {
			window.gTagManagerDataLayer.push(params);
			sent = true;
		}
	}

	if (lsTest() && localStorage.getItem('debugTracking')) {
		if (sent) {
			console.log(
				'%cGoogle Tag Manager:',
				'color: white; background: #D2691E; font-size: 11px; font-weight: bold; border-radius: 5px; padding: 5px 10px; margin: 5px 0px;',
				JSON.stringify(params, null, 3)
			);
		} else {
			console.error(
				'%c🔥 Google Tag Manager failed! 🔥',
				'color: white; background: #D2691E; border-color: red; font-size: 11px; font-weight: bold; border-radius: 5px; padding: 5px 10px; margin: 5px 0px;',
				JSON.stringify(params, null, 3)
			);
		}
	}
}

// to debug hotjar events locally use query '?hjDebug=1'
export async function __sendHotjarEvent(eventName: string) {
	let sent = false;
	if (!window.hj) {
		return;
	}
	window.hj('event', eventName);
	sent = true;
	if (lsTest() && localStorage.getItem('debugTracking')) {
		if (sent) {
			console.log(
				`%cHotjar Event:`,
				'color: white; background: #ff3c00; font-size: 11px; font-weight: bold; border-radius: 5px; padding: 5px 10px; margin: 5px 0px;',
				eventName
			);
		} else {
			console.error(
				'%c🔥 Hotjar Event failed! 🔥',
				'color: white; background: #ff3c00; border-color: red; font-size: 11px; font-weight: bold; border-radius: 5px; padding: 5px 10px; margin: 5px 0px;',
				eventName
			);
		}
	}
}

export async function __sendCriteoEvent(store: any, param?: any) {
	if (!acceptedTracking) {
		trackingQueue.push({ type: 'criteo', data: [store, param] });
		if (lsTest() && localStorage.getItem('debugTracking')) {
			console.log(
				'%cTracking Event pushed to queue: criteo',
				'color: white; background: #D3D3D3; font-size: 11px; font-weight: bold; border-radius: 5px; padding: 5px 10px; margin: 5px 0px;'
			);
		}
		return;
	}

	let sent = false;
	const topLevelDomain = store?.state?.topLevelDomain || store?.$userRootStore?.topLevelDomain;
	const userEmail =
		store?.state?.user?.profile?.obj?.general?.email ||
		store?.$userProfileStore?.obj?.general?.email;

	if (enabledTrackings.criteo) {
		if (process.client && typeof window.criteo_q !== 'undefined' && window.criteo_q && store) {
			const criteoAccount =
				(topLevelDomain && {
					event: 'setAccount',
					account: trackingConfigDomain[topLevelDomain].criteoTracker
				}) ||
				undefined;

			const criteoEmail =
				(userEmail && {
					event: 'setEmail',
					email: await createHashedValue(userEmail),
					hash_method: 'sha256'
				}) ||
				undefined;

			// code snipped comes from https://guides.criteotilt.com/onetag/onetag_complete/?accountid=100255
			// &advertisername=Hokify+AT&subdomain=&domain=&homepageurl=https%3A%2F%2Fhokify.at%2F
			// &accuratekey=hokifyat%3B7920018%3B7920019%3B100255#visit-tag
			// eslint-disable-next-line no-nested-ternary
			const deviceType = /iPad/.test(navigator.userAgent)
				? 't'
				: /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent)
					? 'm'
					: 'd';

			window.criteo_q.push(
				criteoAccount,
				criteoEmail,
				{ event: 'setSiteType', type: deviceType },
				param
			);
			sent = true;
		}
	}

	if (lsTest() && localStorage.getItem('debugTracking')) {
		if (sent) {
			console.log(
				`%c Criteo Event:`,
				'color: white; background:#ff891f; font-size: 11px; font-weight: bold; border-radius: 5px; padding: 5px 10px; margin: 5px 0px;',
				JSON.stringify(param, null, 3)
			);
		} else {
			console.error(
				`%c🔥 Criteo Event failed! 🔥`,
				'color: white; background:#ff891f; border-color: red; font-size: 11px; font-weight: bold; border-radius: 5px; padding: 5px 10px; margin: 5px 0px;',
				JSON.stringify(param, null, 3)
			);
		}
	}
}

export async function __sendRTBHouseEvent(store: any, param?: any) {
	if (!acceptedTracking) {
		trackingQueue.push({ type: 'rtbhouse', data: [store, param] });
		if (lsTest() && localStorage.getItem('debugTracking')) {
			console.log(
				'%cTracking Event pushed to queue: rtbHouse',
				'color: white; background: #D3D3D3; font-size: 11px; font-weight: bold; border-radius: 5px; padding: 5px 10px; margin: 5px 0px;'
			);
		}
		return;
	}

	let sent = false;
	const userEmail = store?.state?.user?.profile?.obj?.general?.email || store?.obj?.general?.email;

	if (enabledTrackings.rtbhouse) {
		if (process.client && typeof window.rtbhEvents !== 'undefined' && window.rtbhEvents && store) {
			const uid =
				(userEmail && {
					eventType: 'uid',
					id: await createHashedValue(userEmail)
				}) ||
				undefined;

			window.rtbhEvents.push(uid, param);
			sent = true;
		}
	}

	if (lsTest() && localStorage.getItem('debugTracking')) {
		if (sent) {
			console.log(
				`%c RTBHouse Event:`,
				'color: white; background:#a976e7; font-size: 11px; font-weight: bold; border-radius: 5px; padding: 5px 10px; margin: 5px 0px;',
				JSON.stringify(param, null, 3)
			);
		} else {
			console.error(
				`%c🔥 RTBHouse Event failed! 🔥`,
				'color: white; background:#a976e7; border-color: red; font-size: 11px; font-weight: bold; border-radius: 5px; padding: 5px 10px; margin: 5px 0px;',
				JSON.stringify(param, null, 3)
			);
		}
	}
}

export async function __sendTiktokEvent(store: any, param?: any) {
	if (!acceptedTracking) {
		trackingQueue.push({ type: 'tiktok', data: [store, param] });
		if (lsTest() && localStorage.getItem('debugTracking')) {
			console.log(
				'%cTracking Event pushed to queue: tiktok',
				'color: white; background: #D3D3D3; font-size: 11px; font-weight: bold; border-radius: 5px; padding: 5px 10px; margin: 5px 0px;'
			);
		}
		return;
	}

	let sent = false;
	const userEmail = store?.state?.user?.profile?.obj?.general?.email || store?.obj?.general?.email;

	if (enabledTrackings.tiktok) {
		if (process.client && typeof window.ttq !== 'undefined' && window.ttq && store) {
			const user_hashed_mail =
				(userEmail && {
					sha256_email: await createHashedValue(userEmail)
				}) ||
				''; // https://business-api.tiktok.com/portal/docs?id=1739585700402178
			// Pass empty string when customer info is not available rather than using blank spaces, "undefined", or other hardcoded values.

			window.ttq.identify(user_hashed_mail);
			window.ttq.instance('CI8OFQJC77U4TTM9HQKG').track(param.type, param.data);
			sent = true;
		}
	}

	if (lsTest() && localStorage.getItem('debugTracking')) {
		if (sent) {
			console.log(
				`%c Tiktok Event:`,
				'color: white; background:#06ebe6; font-size: 11px; font-weight: bold; border-radius: 5px; padding: 5px 10px; margin: 5px 0px;',
				JSON.stringify(param, null, 3)
			);
		} else {
			console.error(
				`%c🔥 Tiktok Event failed! 🔥`,
				'color: white; background:#a976e7; border-color: red; font-size: 11px; font-weight: bold; border-radius: 5px; padding: 5px 10px; margin: 5px 0px;',
				JSON.stringify(param, null, 3)
			);
		}
	}
}

export async function __sendSnapchatEvent(store: any, param?: any) {
	if (!acceptedTracking) {
		trackingQueue.push({ type: 'snapchat', data: [store, param] });
		if (lsTest() && localStorage.getItem('debugTracking')) {
			console.log(
				'%cTracking Event pushed to queue: snapchat',
				'color: black; background: #fffc00; font-size: 11px; font-weight: bold; border-radius: 5px; padding: 5px 10px; margin: 5px 0px;'
			);
		}
		return;
	}

	let sent = false;
	const userEmail = store?.state?.user?.profile?.obj?.general?.email || store?.obj?.general?.email;
	const userPhone = store?.state?.user?.profile?.obj?.general?.phone;

	if (enabledTrackings.snapchat) {
		if (process.client && typeof window.snaptr !== 'undefined' && window.snaptr && store) {
			const user_hashed_email = userEmail ? await createHashedValue(userEmail) : '';

			const user_hashed_phone_number = userPhone ? await createHashedValue(userPhone) : '';

			window.snaptr('init', 'e067321f-415b-4629-99de-f87641cb5dc5', {
				user_hashed_email,
				user_hashed_phone_number
			});

			// needs detailed check with marketing, if it makes sense to track pageView
			// window.snaptr('track', 'PAGE_VIEW');
			window.snaptr('track', param.type, param.data);
			sent = true;
		}
	}

	if (lsTest() && localStorage.getItem('debugTracking')) {
		if (sent) {
			console.log(
				`%c Snapchat Event:`,
				'color: black; background: #fffc00;  font-size: 11px; font-weight: bold; border-radius: 5px; padding: 5px 10px; margin: 5px 0px;',
				JSON.stringify(param, null, 3)
			);
		} else {
			console.error(
				`%c🔥 Snapchat Event failed! 🔥`,
				'color: white; background:#a976e7; border-color: red; font-size: 11px; font-weight: bold; border-radius: 5px; padding: 5px 10px; margin: 5px 0px;',
				JSON.stringify(param, null, 3)
			);
		}
	}
}

async function __sendGAEvent(
	eventType: 'pageview' | 'screenview',
	params: {
		page: string;
	} & GARemaTrackingParameters
);

async function __sendGAEvent<EVENTTYPE extends keyof ITrackWebsiteEvents>(
	eventType: EVENTTYPE,
	params: ITrackWebsiteEvents[EVENTTYPE],
	skipQueue?: boolean
);

async function __sendGAEvent<EVENTTYPE extends keyof ITrackCompanyEvents>(
	eventType: EVENTTYPE,
	params: ITrackCompanyEvents[EVENTTYPE],
	skipQueue?: boolean
);

async function __sendGAEvent<EVENTTYPE extends keyof ITrackUserEvents>(
	eventType: EVENTTYPE,
	params: ITrackUserEvents[EVENTTYPE],
	skipQueue?: boolean
);

async function __sendGAEvent<EVENTTYPE extends keyof ITrackGenericEvents>(
	eventType: EVENTTYPE,
	params: ITrackGenericEvents[EVENTTYPE],
	skipQueue?: boolean
);

/* START - Measure ecommerce https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm */
async function __sendGAEvent(
	eventType: 'add_to_cart' | 'begin_checkout' | 'add_shipping_info',
	params: {
		currency: string; // e.g. 'USD'
		value: number; // e.g. 7.77
		items: ICheckoutItem[];
	}
);

async function __sendGAEvent(
	eventType: 'add_payment_info',
	params: {
		currency: string; // e.g. 'USD'
		value: number; // e.g. 7.77
		coupon?: string;
		payment_type?: string; // 'Kreditkarte' | 'Paypal' | 'Rechnung'
		items: ICheckoutItem[];
	}
);

async function __sendGAEvent(
	eventType: 'purchase',
	params: {
		currency: string; // e.g. 'USD'
		transaction_id: string;
		value: number; // e.g. 7.77
		affiliation?: string; // e.g. 'Google Store'
		coupon?: string;
		tax?: number; // e.g. 1.11
		items: ICheckoutItem[];
	}
);

async function __sendGAEvent(
	eventType: 'select_item' | 'view_item_list',
	params: {
		item_list_id?: string;
		item_list_name?: string;
		items: ICheckoutItem[]; // only the first item will be used
	}
);

/* END - Measure ecommerce */
// company signup
async function __sendGAEvent(
	eventType: 'register_completed' | 'company_register_completed',
	params: ITrackGenericEvents['register_completed']
);
async function __sendGAEvent(
	eventType: 'login' | 'company_login',
	params: ITrackGenericEvents['login']
);
async function __sendGAEvent(
	eventType:
		| 'add_payment_info'
		| 'add_shipping_info'
		| 'add_to_cart'
		| 'begin_checkout'
		| 'pageview'
		| 'screenview'
		| 'purchase'
		| 'select_item'
		| 'view_item_list'
		| 'company_register_completed'
		| 'company_login'
		| keyof ITrackWebsiteEvents
		| keyof ITrackCompanyEvents
		| keyof ITrackUserEvents
		| keyof ITrackGenericEvents,
	params?: { [key: string]: unknown },
	skipQueue = false
) {
	if (!acceptedTracking && !skipQueue) {
		trackingQueue.push({ type: 'google', data: [eventType, params] as any });
		if (lsTest() && localStorage.getItem('debugTracking')) {
			console.log(
				'%cTracking event pushed to queue: google',
				'color: white; background: #D3D3D3; font-size: 11px; font-weight: bold; border-radius: 5px; padding: 5px 10px; margin: 5px 0px;',
				eventType,
				params
			);
		}
		return;
	}

	let sent = false;
	if (process.client && (enabledTrackings.google || skipQueue)) {
		if (process.env.cordova) {
			// USE FIREBASE INSTEAD
			switch (eventType) {
				case 'pageview': {
					// page view inside the apps is a screen view
					const { page, ...otherParams } = params || {};
					window.cordova?.plugins?.firebase.analytics.logEvent('screen_view', {
						...otherParams,
						firebase_screen: page
					});
					sent = true;
					break;
				}
				default:
					/* custom events */
					window.cordova?.plugins?.firebase.analytics.logEvent(eventType, params);
					sent = true;
					break;
			}
		} else if (gtagAvailable(window)) {
			switch (eventType) {
				case 'pageview': {
					const { page, ...otherParams } = params || {};
					window.gtag('event', 'page_view', {
						...otherParams,
						page_location: page
					});
					sent = true;
					break;
				}
				case 'screenview': {
					const { page, ...otherParams } = params || {};
					window.gtag('event', 'screen_view', {
						...otherParams,
						page_location: page
					});
					sent = true;
					break;
				}
				default:
					/* custom events */
					window.gtag('event', eventType, params);
					sent = true;
					// send hotjar event
					await __sendHotjarEvent(eventType);
					break;
			}
		}
	}

	if (lsTest() && localStorage.getItem('debugTracking')) {
		if (!sent) {
			console.error(
				'%c🔥 GA4 Tracking failed! 🔥',
				'color:white; background:darkorange; border-color: red; font-size: 11px; font-weight: bold; border-radius: 5px; padding: 5px 10px; margin: 5px 0px;',
				eventType,
				params
			);
		} else if (eventType === 'screenview') {
			const { page, ...otherParams } = params || {};
			console.log(
				'%cGA4 SCREENVIEW:',
				'color:white; background:darkorange; font-size: 11px; font-weight: bold; border-radius: 5px; padding: 5px 10px; margin: 5px 0px;',
				page,
				otherParams
			);
		} else if (eventType === 'pageview') {
			const { page, ...otherParams } = params || {};
			console.log(
				process.env.cordova ? '%cGA4 SCREENVIEW:' : '%cGA4 PAGEVIEW:',
				'color: white; background: darkorange; font-size: 11px; font-weight: bold; border-radius: 5px; padding: 5px 10px; margin: 5px 0px;',
				page,
				otherParams
			);
		} else {
			console.log(
				'%cGA4 EVENT:',
				'color: white; background: orange; font-size: 11px; font-weight: bold; border-radius: 5px; padding: 5px 10px; margin: 5px 0px;',
				eventType,
				params
			);
		}
	}
}

// https://support.google.com/analytics/answer/11259997?hl=en
function __setCampaignData(campaignKey: string, value: string) {
	if (process.client && gtagAvailable(window)) {
		window.gtag('set', { [campaignKey]: value });
	}
}

export type GARemaTrackingParameters = {
	relation_id?: string;
	relation_pagetype?: string;
	relation_totalvalue?: string;
	relation_locid?: string;
	job_top_fields?: string;
	job_type?: string;
	job_fields?: string;
	job_location?: string;
	is_logged_in?: 0 | 1;
};

// beware: this method is used by other scripts too (and can therefore be executed on server side too)
export function setTrackingSource(
	source: string,
	medium: string,
	campaign: string | undefined,
	content: string | undefined,
	keyword: string | undefined,
	req: { headers?: { cookie?: string } } | undefined,
	res: unknown | undefined
) {
	if (lsTest() && localStorage.getItem('debugTracking')) {
		console.info('setting tracking source to', source, medium, campaign, content, keyword);
	}

	// hokify
	let cookies: string | undefined;
	if (process.server && req) {
		cookies = req.headers?.cookie;
	} else if (process.client) {
		cookies = window.document.cookie;
	}

	const existingUTMs = cookies && getCookie('utm', cookies);

	if (!existingUTMs) {
		const attrUtmSourceParts = [source, medium, campaign, content].filter(u => !!u);

		const attrUTMs = attrUtmSourceParts.length > 0 ? attrUtmSourceParts.join(',') : undefined;

		if (attrUTMs) {
			setCookie('utm', attrUTMs, 7, res);
		}
	} else if (lsTest() && localStorage.getItem('debugTracking')) {
		console.info(
			'hokify tracking source already set to',
			existingUTMs,
			'skipping setting tracking cookie to',
			source,
			medium,
			campaign,
			content,
			keyword
		);
	}

	if (campaign) {
		__setCampaignData('campaign_name', campaign);
	}
	if (source) {
		__setCampaignData('campaign_source', source);
	}
	if (medium) {
		__setCampaignData('campaign_medium', medium);
	}
	if (content) {
		__setCampaignData('campaign_content', content);
	}
	if (keyword) {
		__setCampaignData('campaign_term', keyword);
	}
}

export function __isCustomTracked(to): boolean {
	/* to disable default page views, add
		<router>
			{
				"meta": { "trackPageView": "disable" }
			}
		</router>
		to page vue file
 	*/
	return to.meta?.trackPageView === 'disable';
}

const facebookSDKAvailable = (window: any): window is { facebookConnectPlugin: any } =>
	!!(
		process.env.cordova &&
		typeof window.facebookConnectPlugin !== 'undefined' &&
		window.facebookConnectPlugin
	);

export async function __sendFbEvent(
	type: 'track' | 'trackCustom',
	mode: 'jobseeker' | 'company',
	event: string,
	param?: any,
	dedupParams?: { eventID: string }
) {
	if (!acceptedTracking) {
		trackingQueue.push({ type: 'facebook', data: [type, mode, event, param] });
		if (lsTest() && localStorage.getItem('debugTracking')) {
			console.log(
				'%cTracking Event pushed to queue: facebook',
				'color: white; background: #D3D3D3; font-size: 11px; font-weight: bold; border-radius: 5px; padding: 5px 10px; margin: 5px 0px;',
				type,
				mode,
				event
			);
		}
		return;
	}

	let sent = false;

	if (enabledTrackings.facebook) {
		if (process.client && typeof window.fbq !== 'undefined' && window.fbq) {
			// WEB events https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking/#parameters
			switch (type) {
				case 'track':
					// eslint-disable-next-line
					const paramTransform = typeof param === 'string' ? { param } : param;
					window.fbq(
						'trackSingle',
						FacebookConfig[mode].pixelId,
						event,
						paramTransform,
						dedupParams
					);
					break;
				case 'trackCustom':
					window.fbq('trackSingleCustom', FacebookConfig[mode].pixelId, event, param, dedupParams);
					break;
				default:
					throw new Error(`invalid fb tracking type: ${type}`);
			}

			sent = true;
		} else if (process.client && facebookSDKAvailable(window)) {
			console.warn('fallback to log directly with facebookConnectPlugin!');
			// APP Events https://developers.facebook.com/docs/reference/javascript/FB.AppEvents.LogEvent
			// PageViews are tracked automatically
			if (type === 'track') {
				// pre defined events
				switch (event) {
					case FacebookEvent.AddPaymentInfo:
						window.facebookConnectPlugin.logEvent('ADDED_PAYMENT_INFO', param);
						break;
					case FacebookEvent.AddToCart:
						window.facebookConnectPlugin.logEvent('ADDED_TO_CART', param);
						break;
					case FacebookEvent.ViewContent:
						window.facebookConnectPlugin.logEvent('VIEWED_CONTENT', param);
						break;
					case FacebookEvent.CompleteRegistration:
						window.facebookConnectPlugin.logEvent('COMPLETED_REGISTRATION', param);
						break;
					case FacebookEvent.InitiateCheckout:
						window.facebookConnectPlugin.logEvent('INITIATED_CHECKOUT', param);
						break;
					case FacebookEvent.Purchase:
						window.facebookConnectPlugin.logPurchase(
							'ADDED_PAYMENT_INFO',
							param.value,
							param.currency,
							param.content_name,
							param.content_type,
							param.content_ids
						);
						break;
					case FacebookEvent.LevelAchieved:
						window.facebookConnectPlugin.logEvent('ACHIEVED_LEVEL', param);
						break;
					default:
						window.facebookConnectPlugin.logEvent(event, param);
				}
			} else {
				// custom event
				window.facebookConnectPlugin.logEvent(event, param);
			}
			sent = true;
		}
	}

	if (lsTest() && localStorage.getItem('debugTracking')) {
		if (type === 'trackCustom' && sent) {
			console.log(
				`%cFacebook CustomEvent${
					facebookSDKAvailable(process.client && window) ? ' SDK' : ''
				} (${mode}):`,
				'color: white; background: #4267B2; font-size: 11px; font-weight: bold; border-radius: 5px; padding: 5px 10px; margin: 5px 0px;',
				event,
				JSON.stringify(param, null, 3)
			);
		} else if (sent) {
			console.log(
				`%cFacebook Event${
					facebookSDKAvailable(process.client && window) ? ' SDK' : ''
				} (${mode}):`,
				'color: white; background: #4267B2; font-size: 11px; font-weight: bold; border-radius: 5px; padding: 5px 10px; margin: 5px 0px;',
				event,
				JSON.stringify(param, null, 3)
			);
		} else {
			console.error(
				`%c🔥 Facebook Event failed! (${mode}) 🔥`,
				'color: white; background: #4267B2; border-color: red; font-size: 11px; font-weight: bold; border-radius: 5px; padding: 5px 10px; margin: 5px 0px;',
				event,
				JSON.stringify(param, null, 3),
				enabledTrackings
			);
		}
	}
}

// tracks screen (app) or page view (web)
export async function trackview(
	page: string,
	mode: 'jobseeker' | 'company',
	remaOptions?: GARemaTrackingParameters
) {
	let pagePath: string = page;
	let pathStart = '';

	if (process.env.baseURL) {
		pathStart = process.env.baseURL;
	}

	if (page.includes('#/')) {
		pagePath = page.substr(page.indexOf('#/') + 1);
	}

	if (!pagePath.startsWith('/')) {
		pagePath = `/${pagePath}`;
	}

	// add respective prefix for virtual pageviews
	if (!pagePath.startsWith(pathStart)) {
		pagePath = pathStart + pagePath;
	}

	// on the web, we use the full path for GA tracking (including domain)
	await __sendGAEvent('pageview', {
		...(remaOptions || {}),
		page: !process.env.cordova ? window.location.origin + pagePath : pagePath
	});

	await __sendFbEvent('track', mode, 'PageView', pagePath);
}

export async function __websiteTracking(
	ctx: Context,
	params: {
		uref?: string;
		cref?: string;
		detailView?: {
			relation: 'company' | 'job' | 'article' | 'lp';
			relationId: APITypeObjectId<
				| APIObjectType.Company
				| APIObjectType.Job
				| APIObjectType.Article
				| APIObjectType.Landingpage
			>;
		};
		listView?: {
			relation: 'company' | 'job' | 'article' | 'lp';
			relationId: APITypeObjectId<
				| APIObjectType.Company
				| APIObjectType.Job
				| APIObjectType.Article
				| APIObjectType.Landingpage
			>;
		}[];
	}
) {
	const { store } = ctx;

	const trackingOptions: IWebsiteTrackingOptions = {
		...params,
		utm: getCookie('utm', window.document.cookie) || undefined,
		uref: params.uref || ctx.query?.uref?.toString(),
		cref: params.cref || ctx.query?.cref?.toString(),
		gaCID: await getGoogleAnalyticsClientId()
	};

	try {
		await store.dispatch('general/trackAction', trackingOptions);
		if (lsTest() && localStorage.getItem('debugTracking')) {
			console.log(
				'%cJobSeekerTracking:',
				'color: white; background: #0fb1af; font-size: 11px; font-weight: bold; border-radius: 5px; padding: 5px 10px; margin: 5px 0px;',
				trackingOptions
			);
		}
	} catch (err) {
		if (lsTest() && localStorage.getItem('debugTracking')) {
			console.error(
				'%c🔥 JobSeekerTracking failed! 🔥',
				'color: white; background: #0fb1af; border-color: red; font-size: 11px; font-weight: bold; border-radius: 5px; padding: 5px 10px; margin: 5px 0px;',
				trackingOptions,
				err
			);
		}
		// ctx.$errorHandler.call(this, err, null);
	}
}

export async function __companyActionTracking(
	ctx: Context,
	trackingOptions:
		| { event: 'jobaddclick' }
		| { event: 'addcreditclick' }
		| {
				event: 'discoverclick';
				jobId: APITypeObjectId<APIObjectType.Job>;
		  }
		| {
				event: 'discoverclick';
				companyId: APITypeObjectId<APIObjectType.Company>;
		  }
		| {
				event: 'applicantdiscover';
				jobId: APITypeObjectId<APIObjectType.Job>;
				matchId: APITypeObjectId<APIObjectType.Match>;
		  }
		| {
				event: 'applicantdiscover';
				companyId: APITypeObjectId<APIObjectType.Company>;
				matchId: APITypeObjectId<APIObjectType.Match>;
		  }
) {
	try {
		const { $companyStore } = ctx;
		await $companyStore.backendTracking(trackingOptions);

		if (lsTest() && localStorage.getItem('debugTracking')) {
			console.log(
				'%cCompanyActionTracking:',
				'color: white; background: #007f7E; font-size: 11px; font-weight: bold; border-radius: 5px; padding: 5px 10px; margin: 5px 0px;',
				trackingOptions
			);
		}
	} catch (err) {
		if (lsTest() && localStorage.getItem('debugTracking')) {
			console.error(
				'%c🔥 cCompanyActionTracking failed! 🔥',
				'color: white; background: #007f7E; border-color: red; font-size: 11px; font-weight: bold; border-radius: 5px; padding: 5px 10px; margin: 5px 0px;',
				trackingOptions,
				err
			);
		}
		// ctx.$errorHandler.call(this, err, null);
	}
}

export async function __jobSeekerTracking(
	ctx: Context,
	params:
		| { relations: TrackingRelation[] }
		| { job: { _id: APITypeObjectId<APIObjectType.Job> } }
		| {
				company: {
					_id: APITypeObjectId<APIObjectType.Company>;
					audienceId?: APITypeObjectId<APIObjectType.CompanyAudience>;
				};
		  },
	action: TrackingActions
) {
	const { $relationsStore } = ctx;
	let relations: TrackingRelation[] = [];

	if (hasRelations(params)) {
		relations = params.relations;
	} else if (hasJob(params)) {
		const relation = 'job';
		const relationId = params.job?._id;
		relations.push({ relation, relationId });
	} else if (hasCompany(params)) {
		const relation = 'company';
		const relationId = params.company?._id;
		const audienceId = params.company?.audienceId;
		relations.push({ relation, relationId, audienceId });
	}

	const trackingOptions: IJobSeekerTrackingOptions = {
		relations: relations.length ? relations : undefined,
		action,
		source: process.env.cordova === 'apple' ? 'ios' : process.env.cordova || 'pwa',
		utm: getCookie('utm', window.document.cookie),
		uref: ctx.query?.uref?.toString(),
		cref: ctx.query?.cref?.toString(),
		gaCID: await getGoogleAnalyticsClientId()
	};

	try {
		await $relationsStore?.jobSeekerTracking(trackingOptions);

		if (lsTest() && localStorage.getItem('debugTracking')) {
			console.log(
				'%cJobSeekerTracking:',
				'color: white; background: #0fb1af; font-size: 11px; font-weight: bold; border-radius: 5px; padding: 5px 10px; margin: 5px 0px;',
				trackingOptions
			);
		}
	} catch (err) {
		if (lsTest() && localStorage.getItem('debugTracking')) {
			console.error(
				'%c🔥 JobSeekerTracking failed! 🔥',
				'color: white; background: #0fb1af; border-color: red; font-size: 11px; font-weight: bold; border-radius: 5px; padding: 5px 10px; margin: 5px 0px;',
				trackingOptions,
				err
			);
		}
		// ctx.$errorHandler.call(this, err, null);
	}
}

async function __trackRecruiter(ctx, trackingOptions: IRecruiterTrackingOptions) {
	if (!acceptedTracking) {
		trackingQueue.push({ type: 'recruiterTracking', data: [ctx, trackingOptions] });
		if (lsTest() && localStorage.getItem('debugTracking')) {
			console.log(
				'%cTracking event pushed to queue: recruiterTracking',
				'color: white; background: #D3D3D3; font-size: 11px; font-weight: bold; border-radius: 5px; padding: 5px 10px; margin: 5px 0px;',
				trackingOptions
			);
		}
		return;
	}

	let sent = false;
	if (enabledTrackings.google) {
		try {
			const { store, $companyStore } = ctx;

			// this can be either the website-app store or the company-app store!
			const call = $companyStore
				? $companyStore.recruiterTracking(trackingOptions)
				: store.dispatch('company/recruiterTracking', trackingOptions);

			await call;
			sent = true;
			if (lsTest() && localStorage.getItem('debugTracking')) {
				console.log(
					'%cRecruiterTracking:',
					'color: white; background: #007f7E; font-size: 11px; font-weight: bold; border-radius: 5px; padding: 5px 10px; margin: 5px 0px;',
					trackingOptions
				);
			}
		} catch (err) {
			if (lsTest() && localStorage.getItem('debugTracking')) {
				console.error(
					'%c🔥 RecruiterTracking failed! 🔥',
					'color: white; background: #007f7E; border-color: red; font-size: 11px; font-weight: bold; border-radius: 5px; padding: 5px 10px; margin: 5px 0px;',
					trackingOptions,
					err
				);
			}
		}
	}
	if (!sent) {
		if (lsTest() && localStorage.getItem('debugTracking')) {
			console.error(
				'%c🔥 RecruiterTracking failed! 🔥',
				'color: white; background: #007f7E; border-color: red; font-size: 11px; font-weight: bold; border-radius: 5px; padding: 5px 10px; margin: 5px 0px;',
				trackingOptions
			);
		}
	}
}

export const trackRecruiter =
	ctx =>
	async (
		params: {
			button_id?: string;
			item?: string;
			purchased_items?: APITypeObjectId<APIObjectType.PurchasedPackage>[];
		},
		action: B2BPerformanceStatsAction
	) => {
		// due to the type of 'source' in BE is RelationPerformanceStatsSource
		// in this case it can only be 'pwa' | 'website' | 'android' | 'ios'
		let source;
		if (process.env.cordova) {
			if (process.env.cordova === 'apple') {
				source = 'ios';
			} else {
				source = 'android';
			}
		} else if (process.env.appType === 'website') {
			source = 'website';
		} else {
			source = 'pwa';
		}

		const trackingOptions: IRecruiterTrackingOptions = {
			event_name: action,
			button_id: params?.button_id,
			item: params?.item,
			purchased_items: params?.purchased_items,
			page_location: window.location.origin + window.location.pathname + window.location.search,
			utm: getCookie('utm', window.document.cookie),
			uref: ctx.query?.uref?.toString(),
			cref: ctx.query?.cref?.toString(),
			source
			// gaCID: await getGoogleAnalyticsClientId()
			// this would be the ID from the false tracker (because the one from recruiterTracking does not get initialised in
			// the frontend at all) we do not use this therefore. instead we create a random ID in BE and use it as gaCID
		};

		await __trackRecruiter(ctx, trackingOptions);
	};

export const trackWebsite =
	ctx =>
	async <ACTION extends keyof ITrackWebsiteEvents>(
		action: ACTION,
		params: unknown // ITrackUserEvents[ACTION]
		// - any is required until https://github.com/microsoft/TypeScript/issues/33014 & https://github.com/microsoft/TypeScript/issues/43873
		// are fixed
	) => {
		if (lsTest() && localStorage.getItem('debugTracking')) {
			console.log(
				'%c$trackWebsite:',
				'color: white; background: #0FB1AF; font-size: 11px; font-weight: bold; border-radius: 5px; padding: 5px 10px; margin: 5px 0px;',
				action,
				params
			);
		}

		const { store } = ctx;

		// ALL TRACKING
		switch (action) {
			case 'complete_onboarding': {
				const trackingParams = params as ITrackWebsiteEvents['complete_onboarding'];
				let jobAd;
				// for optin, we are interested if the user chooses to publish the prepared job ad or even prefers to create a new one
				if (trackingParams.companySignupMode === 'optin') {
					if (trackingParams.jobAd === undefined) {
						jobAd = 'createNew';
					} else {
						jobAd = trackingParams.jobAd ? 'publishPrepared' : 'editPrepared';
					}
				}
				await __sendGAEvent('complete_onboarding', {
					companySignupMode: trackingParams.companySignupMode,
					jobAd
				});
				break;
			}
			case 'go_to_pricing_page': {
				await __sendGAEvent(action, params as any);
				await __sendToTagManager({
					event: 'b2b_lp_click_completed',
					mode: 'company'
				});
				break;
			}
			case 'select_package': {
				const trackingParams = params as ITrackWebsiteEvents['select_package'];
				const selectedPackage = {
					items: [
						{
							item_id: trackingParams.packageVariant._id,
							item_name: trackingParams.packageVariant.name,
							currency: 'EUR',
							item_category: 'Stellenpakete',
							price: trackingParams.packageVariant.price,
							quantity: trackingParams.quantity || 1
						}
					]
				};
				await __sendGAEvent('select_item', selectedPackage);
				await __sendFbEvent('track', 'company', FacebookEvent.AddToCart, {});
				break;
			}
			case 'view_pricelist_packages': {
				const trackingParams = params as ITrackWebsiteEvents['view_pricelist_packages'];
				const itemList = {
					items: trackingParams.packageVariants.map(item => ({
						item_id: item._id,
						item_name: item.name,
						currency: 'EUR',
						item_category: 'Stellenpakete',
						price: item.price
					}))
				};
				await __sendGAEvent('view_item_list', itemList);
				break;
			}
			case 'article_view': {
				const trackingParams = params as ITrackWebsiteEvents['article_view'];
				await __websiteTracking(ctx, {
					detailView: { relation: 'article', relationId: trackingParams.articleId }
				});
				break;
			}
			case 'jobalarm_list_view': {
				const trackingParams = params as ITrackWebsiteEvents['jobalarm_list_view'];
				await __websiteTracking(ctx, {
					listView: trackingParams.jobs?.map(job => ({ relation: 'job', relationId: job._id }))
				});
				break;
			}
			case 'click_joblist_pagination': {
				const trackingParams = params as ITrackWebsiteEvents['click_joblist_pagination'];
				await __websiteTracking(ctx, {
					listView: trackingParams.jobs?.map(job => ({ relation: 'job', relationId: job._id }))
				});
				await __sendGAEvent('click_joblist_pagination', trackingParams);
				break;
			}
			case 'company_view': {
				const trackingParams = params as ITrackWebsiteEvents['company_view'];
				// hokify internal tracking
				let detailView;
				if (trackingParams.companyId) {
					detailView = { relation: 'company', relationId: trackingParams.companyId };
				}
				await __websiteTracking(ctx, {
					detailView,
					listView: trackingParams.jobs?.map(job => ({ relation: 'job', relationId: job._id }))
				});

				// we want to send something else to GA, therefore as any is needed
				// __sendGAEvent('company_view' as any, { item_id: trackingParams.companyId });

				// company_view is not automatically tracked
				// regular page view - auto trackPageView is disabled on this page
				await trackview(window.location.pathname + window.location.search, 'jobseeker', {
					relation_id: trackingParams.jobs.map(j => `J${j.jobNr}`).join(','), // same as: dimension1: `J${params.jobNrs.join(',J')}`,
					relation_pagetype: GoogleRemarketingPageType.ListView,
					relation_locid: trackingParams.topLevelDomain.replace(/\W/g, '').toUpperCase()
				});

				await __sendFbEvent('track', 'jobseeker', FacebookEvent.ViewContent, {
					content_type: 'product',
					content_ids: trackingParams.jobs.map(j => `J${j.jobNr}`).join(','),
					topfields: trackingParams.topfields,
					jobfields: trackingParams.jobfields,
					keywords: trackingParams.keywords,
					branch: trackingParams.branch ? trackingParams.branch : null
				});
				break;
			}
			case 'startpage_view': {
				const trackingParams = params as ITrackWebsiteEvents['startpage_view'];
				// regular page view - auto trackPageView is disabled on this page (apps/website-app/src/pages/index.vue)
				await trackview(window.location.pathname + window.location.search, 'jobseeker', {
					is_logged_in: trackingParams.is_logged_in
				});
				break;
			}
			case 'joblist_view': {
				const trackingParams = params as ITrackWebsiteEvents['joblist_view'];
				// hokify internal tracking
				let detailView;
				if (trackingParams.lpId) {
					detailView = { relation: 'lp', relationId: trackingParams.lpId };
				}
				await __websiteTracking(ctx, {
					detailView,
					listView: trackingParams.jobs?.map(job => ({ relation: 'job', relationId: job._id }))
				});

				// regular page view - auto trackPageView is disabled on this page
				await trackview(window.location.pathname + window.location.search, 'jobseeker', {
					relation_id: trackingParams.jobs.map(j => `J${j.jobNr}`).join(','), // same as: dimension1: `J${params.jobNrs.join(',J')}`,
					relation_pagetype: GoogleRemarketingPageType.ListView,
					relation_locid: trackingParams.topLevelDomain.replace(/\W/g, '').toUpperCase(),
					is_logged_in: trackingParams.is_logged_in
				});

				await __sendGAEvent('joblist_view', trackingParams);

				await __sendFbEvent('track', 'jobseeker', FacebookEvent.Search, {
					content_type: 'product',
					content_ids: [trackingParams.jobs.map(j => `J${j.jobNr}`).join(',')],
					topfields: trackingParams.topfields,
					jobfields: trackingParams.jobfields,
					keywords: trackingParams.keywords,
					branch: trackingParams.branch ? trackingParams.branch : null
				});

				const jobIds: string[] = [];
				const contents: { content_id: string }[] = [];
				trackingParams.jobs.forEach(job => {
					jobIds.push(`J${job._id}`);
					contents.push({ content_id: `J${job._id}` });
				});

				// rtbHouse can only work with up to 5 IDs
				const rtbHouseOfferIds = jobIds.splice(0, 5);

				await __sendCriteoEvent(store, {
					event: CriteoEventTags.List,
					item: jobIds,
					keywords: trackingParams.branch ? trackingParams.branch : null
				});

				await __sendRTBHouseEvent(store, {
					eventType: RTBHouseEventTags.List,
					offerIds: rtbHouseOfferIds
				});

				await __sendTiktokEvent(store, {
					type: TiktokEventTags.Search,
					data: {
						contents,
						content_type: 'product',
						value: 1,
						currency: 'EUR'
					}
				});

				await __sendSnapchatEvent(store, {
					type: SnapchatEventTags.ListView,
					data: {
						item_ids: jobIds,
						item_category: trackingParams.topfields
					}
				});

				// only send category event if there is a jobField available
				if (trackingParams.jobfields?.[0]) {
					await __sendRTBHouseEvent(store, {
						eventType: RTBHouseEventTags.Category,
						categoryId: trackingParams.jobfields?.[0]
					});
				}

				break;
			}
			case 'job_view': {
				const trackingParams = params as ITrackWebsiteEvents['job_view'];
				let detailView;
				if (trackingParams.jobId) {
					detailView = { relation: 'job', relationId: trackingParams.jobId };
				}

				await __websiteTracking(ctx, {
					uref: trackingParams.uref,
					detailView,
					listView: trackingParams.similarJobs?.map(j => ({ relation: 'job', relationId: j?._id }))
				});

				const trackviewObj: GARemaTrackingParameters = {
					relation_id: `J${trackingParams.jobNr}`,
					relation_pagetype: GoogleRemarketingPageType.ViewContent,
					relation_totalvalue: `${trackingParams.priorize}`,
					is_logged_in: trackingParams.is_logged_in
				};

				if (trackingParams.country) {
					trackviewObj.relation_locid = trackingParams.country;
				}
				if (trackingParams.topfields && trackingParams.topfields.length > 0) {
					trackviewObj.job_top_fields = trackingParams.topfields.join(',');
				}
				if (trackingParams.type) {
					trackviewObj.job_type = trackingParams.type;
				}
				if (trackingParams.jobfields && trackingParams.jobfields.length > 0) {
					trackviewObj.job_fields = trackingParams.jobfields.join(',');
				}
				if (trackingParams.city) {
					trackviewObj.job_location = trackingParams.city;
				}

				// we want to send something else to GA, therefore as any is needed
				// __sendGAEvent('job_view' as any, { item_id: trackingParams.jobId });

				// page view
				await trackview(
					window.location.pathname + window.location.search,
					'jobseeker',
					trackviewObj
				);

				await __sendFbEvent('track', 'jobseeker', FacebookEvent.ViewContent, {
					content_name: trackingParams.name,
					content_type: 'product',
					content_ids: [`J${trackingParams.jobNr}`],
					topfields: trackingParams.topfields ? trackingParams.topfields : [],
					jobfields: trackingParams.jobfields,
					type: trackingParams.type
				});

				await __sendCriteoEvent(store, {
					event: CriteoEventTags.Product,
					item: `J${trackingParams.jobId}`
				});

				await __sendTiktokEvent(store, {
					type: TiktokEventTags.ViewContent,
					data: {
						contents: [
							{
								content_id: `J${trackingParams.jobId}`,
								content_name: trackingParams.name,
								quantity: 1
							}
						],
						content_type: 'product',
						value: 2,
						currency: 'EUR'
					}
				});

				await __sendSnapchatEvent(store, {
					type: SnapchatEventTags.ViewContent,
					data: {
						item_ids: `J${trackingParams.jobId}`,
						item_category: trackingParams.jobfields
					}
				});

				await __sendRTBHouseEvent(store, {
					eventType: RTBHouseEventTags.Product,
					offerId: `J${trackingParams.jobId}`
				});
				break;
			}
			case 'save_job': {
				const trackingParams = params as ITrackWebsiteEvents['save_job'];
				await __sendGAEvent('save_job', trackingParams);

				await __sendFbEvent('track', 'jobseeker', FacebookEvent.AddToCart, {
					content_type: 'product',
					content_ids: [`J${trackingParams.jobNr}`],
					type: trackingParams.item_type
				});

				await __sendCriteoEvent(store, {
					event: CriteoEventTags.Cart,
					item: `J${trackingParams.item_id}`
				});

				await __sendRTBHouseEvent(store, {
					eventType: RTBHouseEventTags.Wishlist,
					offerId: `J${trackingParams.item_id}`
				});

				await __sendSnapchatEvent(store, {
					type: SnapchatEventTags.AddToCart,
					data: {
						item_ids: `J${trackingParams.item_id}`,
						item_category: undefined,
						number_items: 1,
						price: 3,
						currency: 'EUR'
					}
				});
				break;
			}
			case 'submit_lp_contact_form': {
				await __sendToTagManager({
					event: 'submit_contact_form',
					mode: 'company'
				});
				break;
			}
			case 'download_start': {
				await __sendGAEvent(action, params as any);
				await __sendToTagManager({
					event: 'download',
					mode: 'company',
					user_hashed_email: await createHashedValue(
						store.state.user?.profile?.obj?.general?.email
					),
					user_hashed_phone: await createHashedValue(store.state.user?.profile?.obj?.general?.phone)
				});
				break;
			}
			case 'webinar_register_completed': {
				await __sendGAEvent(action, params as any);
				await __sendToTagManager({
					event: 'webinar',
					mode: 'company',
					user_hashed_email: await createHashedValue(
						store.state.user?.profile?.obj?.general?.email
					),
					user_hashed_phone: await createHashedValue(store.state.user?.profile?.obj?.general?.phone)
				});
				break;
			}

			default:
				// by default send GA events
				await __sendGAEvent(action, params as any);
				break;
		}
	};

export const trackCompany =
	ctx =>
	async <ACTION extends keyof ITrackCompanyEvents>(
		action: ACTION,
		params: any // ITrackCompanyEvents[ACTION] // due to a typescript limitation we use any for now, see: https://github.com/microsoft/TypeScript/issues/13995
	) => {
		const { store, $userProfileStore, $paymentStore } = ctx;

		// push everything to google tag manager
		await __sendToTagManager({
			event: action,
			mode: 'company',
			user_hashed_email: await createHashedValue(
				store?.state.user?.profile?.obj?.general?.email || $userProfileStore?.obj?.general?.email
			),
			user_hashed_phone: await createHashedValue(
				store?.state.user?.profile?.obj?.general?.phone || $userProfileStore?.obj?.general?.phone
			),
			...params
		});
		// ALL TRACKING
		switch (action) {
			case 'add_to_cart': {
				const trackingParams = params as ITrackCompanyEvents['add_to_cart'];
				const shoppingCartItem = {
					currency: 'EUR',
					value: trackingParams.shoppingCartItem
						? trackingParams.shoppingCartItem.price * trackingParams.shoppingCartItem.amount
						: 0,
					items: [
						{
							item_id: trackingParams.shoppingCartItem?.variant?._id || '',
							item_name: trackingParams.shoppingCartItem?.variant?.name || '',
							currency: 'EUR',
							item_category: 'Stellenpakete',
							price: trackingParams.shoppingCartItem?.variant?.price,
							quantity: trackingParams.shoppingCartItem?.amount
						}
					]
				};
				await __sendGAEvent('add_to_cart', shoppingCartItem);
				break;
			}
			case 'click_checkout_continue': {
				const trackingParams = params as ITrackCompanyEvents['click_checkout_continue'];
				const shoppingCartItems: IAPIShoppingCartItem[] = $paymentStore?.shoppingCart?.items || [];
				const payload: any = {
					currency: 'EUR',
					value: trackingParams.netSum || 0,
					coupon: '',
					items: shoppingCartItems.map(item => ({
						item_id: item.variant?._id || '',
						item_name: item.variant?.name || '',
						currency: 'EUR',
						item_category: 'Stellenpakete',
						price: item.variant?.price,
						quantity: item.amount
					}))
				};

				if (trackingParams.checkoutStep === 'selection') {
					await __sendGAEvent('begin_checkout', payload);
				} else if (trackingParams.checkoutStep === 'billing') {
					await __sendGAEvent('add_shipping_info', payload);
				} else if (trackingParams.checkoutStep === 'payment') {
					payload.payment_type = trackingParams.paymentMethod;
					await __sendGAEvent('add_payment_info', payload);
				}
				break;
			}
			case 'visit_checkout_view': // gets called on activestep change vie watcher
				await trackview(
					`/checkout${params.checkoutView ? `/${params.checkoutView}` : ''}`,
					'company'
				);
				break;
			case 'select_package': {
				const trackingParams = params as ITrackCompanyEvents['select_package'];
				const selectedPackage = {
					items: [
						{
							item_id: trackingParams.packageVariant._id,
							item_name: trackingParams.packageVariant.name,
							currency: 'EUR',
							item_category: 'Stellenpakete',
							price: trackingParams.packageVariant.price,
							quantity: trackingParams.quantity || 1
						}
					]
				};
				await __sendGAEvent('select_item', selectedPackage);
				await __sendFbEvent('track', 'company', FacebookEvent.AddToCart, {});
				await __companyActionTracking(ctx, { event: 'addcreditclick' });
				break;
			}
			case 'continue_add_job': {
				const trackingParams = params as ITrackCompanyEvents['continue_add_job'];
				await __sendGAEvent('continue_add_job', trackingParams);
				break;
			}
			case 'purchase_completed': {
				const trackingParams = params as ITrackCompanyEvents['purchase_completed'];
				const purchaseObj: any = {
					currency: trackingParams.invoiceBillingData.taxInfo?.currency || 'EUR',
					transaction_id: trackingParams.orderId,
					value: trackingParams.invoiceBillingData.netSum,
					affiliation: '',
					coupon: trackingParams.invoiceBillingData.coupon,
					tax: trackingParams.invoiceBillingData.taxSum,
					items: trackingParams.shoppingCart.items.map(item => ({
						item_id: item.itemId,
						item_name: item.description,
						currency: trackingParams.invoiceBillingData.taxInfo.currency || 'EUR',
						item_category: 'Stellenpakete',
						price: item.price,
						quantity: item.amount
					}))
				};
				await __sendGAEvent('purchase', purchaseObj);
				await trackview('/checkout/completed', 'company');
				await __sendFbEvent('track', 'company', FacebookEvent.Purchase, {
					value: trackingParams.invoiceBillingData.netSum,
					currency: trackingParams.invoiceBillingData.taxInfo.currency
				});
				break;
			}
			case 'publish_job': {
				const trackingParams = params as ITrackCompanyEvents['publish_job'];
				await __sendGAEvent('publish_job', trackingParams);
				await __sendFbEvent('track', 'company', FacebookEvent.SpentCredits, {});
				break;
			}
			case 'add_job': {
				const trackingParams = params as ITrackCompanyEvents['add_job'];
				await __sendGAEvent('add_job', trackingParams);
				await __companyActionTracking(ctx, { event: 'jobaddclick' });
				break;
			}
			case 'start_active_sourcing': {
				const trackingParams = params as ITrackCompanyEvents['start_active_sourcing'];
				await trackview('/active-sourcing', 'company');
				await __sendGAEvent('start_active_sourcing', trackingParams);
				await __companyActionTracking(ctx, {
					event: 'discoverclick',
					jobId: trackingParams.item_id
				});
				break;
			}
			case 'save_job':
				await __sendFbEvent('track', 'jobseeker', FacebookEvent.InitiateCheckout, {
					content_name: params.job.name,
					content_ids: [`J${params.job.jobNr}`],
					content_type: 'product'
				});
				break;
			case 'discover_applicant': {
				await __companyActionTracking(ctx, {
					event: 'applicantdiscover',
					jobId: params.jobId,
					matchId: params.matchId
				});
				break;
			}
			case 'view_pricelist_packages': {
				const trackingParams = params as ITrackCompanyEvents['view_pricelist_packages'];
				const itemList = {
					items: trackingParams.packageVariants.map(item => ({
						item_id: item._id,
						item_name: item.name,
						currency: 'EUR',
						item_category: 'Stellenpakete',
						price: item.price
					}))
				};
				await __sendGAEvent('view_item_list', itemList);
				break;
			}
			default:
				// by default send GA events
				await __sendGAEvent(action, params as any);
				break;
		}
	};

export const trackUser =
	ctx =>
	async <ACTION extends keyof ITrackUserEvents>(
		action: ACTION,
		params: any // ITrackUserEvents[ACTION] // due to a typescript limitation we use any for now, see: https://github.com/microsoft/TypeScript/issues/13995
	) => {
		const { $userProfileStore, $userRootStore } = ctx;
		// push everything to google tag manager
		await __sendToTagManager({
			event: action,
			mode: 'jobseeker',
			user_hashed_email: await createHashedValue($userProfileStore?.obj?.general?.email),
			user_hashed_phone: await createHashedValue($userProfileStore?.obj?.general?.phone),
			...params
		});
		// ALL TRACKING
		switch (action) {
			case 'user_not_verified': // here a modal opens, without the user clicking anything, therefore virtual pageView
				await trackview('user_not_verified', 'jobseeker');
				break;
			case 'app_rating_view': // here a modal opens, without the user clicking anything, therefore virtual pageView
				await trackview('app_rating_view', 'jobseeker');
				break;
			case 'relation_detail_view': {
				await __sendFbEvent('track', 'jobseeker', FacebookEvent.ViewContent, {
					content_name: params.job?.name || params.company?.name || undefined,
					content_type: 'product',
					content_ids:
						(params.job?.jobNr && `J${params.job.jobNr}`) ||
						(params.company?._id && `C${params.company?._id}`) ||
						undefined,
					jobfields: params.job?.jobfields || params.company?.jobfields || undefined,
					type: params.job?.type || undefined
				});
				await __sendCriteoEvent(
					{ $userRootStore, $userProfileStore },
					{
						event: CriteoEventTags.Product,
						item:
							(params.job?._id && `J${params.job._id}`) ||
							(params.company?._id && `C${params.company?._id}`) ||
							undefined
					}
				);
				await __sendRTBHouseEvent($userProfileStore, {
					eventType: RTBHouseEventTags.Product,
					offerId:
						(params.job?._id && `J${params.job._id}`) ||
						(params.company?._id && `C${params.company?._id}`) ||
						undefined
				});
				await __sendTiktokEvent($userProfileStore, {
					type: TiktokEventTags.ViewContent,
					data: {
						contents: [
							{
								content_id:
									(params.job?._id && `J${params.job._id}`) ||
									(params.company?._id && `C${params.company?._id}`) ||
									undefined,
								content_name: params.job?.name || params.company?.name,
								quantity: 1
							}
						],
						content_type: 'product',
						value: 2,
						currency: 'EUR'
					}
				});
				await __sendSnapchatEvent($userProfileStore, {
					type: SnapchatEventTags.ViewContent,
					data: {
						item_ids:
							(params.job?._id && `J${params.job._id}`) ||
							(params.company?._id && `C${params.company?._id}`) ||
							undefined,
						item_category: params.job?.jobfields || params.company?.jobfields || undefined
					}
				});
				await __jobSeekerTracking(ctx, params, 'detail-view');
				break;
			}
			case 'apply':
				// page view disabled here, therefore we track a virtual pageView
				await trackview(`apply/${params?.jobNr}`, 'jobseeker');
				break;
			case 'application_overview': {
				await __sendFbEvent('track', 'jobseeker', FacebookEvent.InitiateCheckout, {
					content_name: params.job?.name || params.company?.name || undefined,
					content_type: 'product',
					content_ids:
						(params.job?.jobNr && `J${params.job.jobNr}`) ||
						(params.company?._id && `C${params.company?._id}`) ||
						undefined,
					jobfields: params.job?.jobfields || params.company?.jobfields || undefined,
					type: params.job?.type || undefined
				});

				await __sendCriteoEvent(
					{ $userRootStore, $userProfileStore },
					{
						event: CriteoEventTags.Basket,
						item: [
							{
								id:
									(params.job?._id && `J${params.job._id}`) ||
									(params.company?._id && `C${params.company?._id}`) ||
									undefined
							}
						]
					}
				);

				await __sendRTBHouseEvent($userProfileStore, {
					eventType: RTBHouseEventTags.StartOrder
				});

				await __sendTiktokEvent($userProfileStore, {
					type: TiktokEventTags.InitiateCheckout,
					data: {
						contents: [
							{
								content_id:
									(params.job?._id && `J${params.job._id}`) ||
									(params.company?._id && `C${params.company?._id}`) ||
									undefined,
								content_name: params.job?.name || params.company?.name,
								quantity: 1
							}
						],
						content_type: 'product',
						value: 7,
						currency: 'EUR'
					}
				});

				await __sendSnapchatEvent($userProfileStore, {
					type: SnapchatEventTags.StartCheckout,
					data: {
						item_ids:
							(params.job?._id && `J${params.job._id}`) ||
							(params.company?._id && `C${params.company?._id}`) ||
							undefined,
						item_category: params.job?.jobfields || params.company?.jobfields || undefined,
						number_items: 1,
						price: 7,
						currency: 'EUR'
					}
				});

				await __sendGAEvent('application_overview' as any, params);
				await __jobSeekerTracking(ctx, params, 'application-overview');
				break;
			}
			case 'list_view':
				await __jobSeekerTracking(ctx, params, 'list-view');
				break;
			case 'detail_view_enlarge':
				await __jobSeekerTracking(ctx, params, 'detail-view-enlarge');
				break;
			case 'activate_active_sourcing': {
				const trackingParams = params as ITrackUserEvents['activate_active_sourcing'];
				await __sendGAEvent('activate_active_sourcing', trackingParams);
				break;
			}
			case 'card_swipe_right': {
				const trackingParams = params as ITrackUserEvents['card_swipe_right'];
				await __sendGAEvent('card_swipe_right', trackingParams);
				break;
			}
			case 'first_start': {
				const trackingParams = params as ITrackUserEvents['first_start'];
				await __sendGAEvent('first_start', trackingParams);
				break;
			}
			case 'share_job': {
				const trackingParams = params as ITrackUserEvents['share_job'];
				await __sendGAEvent('share_job', trackingParams);
				break;
			}
			case 'start_application': {
				await __sendFbEvent(
					'track',
					'jobseeker',
					FacebookEvent.AddToCart,
					{
						content_name: params.job?.name || params.company?.name || undefined,
						content_type: 'product',
						content_ids:
							(params.job?.jobNr && `J${params.job.jobNr}`) ||
							(params.company?._id && `C${params.company?._id}`) ||
							undefined,
						jobfields: params.job?.jobfields || params.company?.jobfields || undefined,
						type: params.job?.type || undefined
					},
					{ eventID: params.matchId }
				);

				const trackingParams = params as ITrackUserEvents['start_application'];
				let itemId = `J${trackingParams.relationId}`;

				if (trackingParams.item_type === 'company') {
					itemId = `C${trackingParams.relationId}`;
				}

				await __sendCriteoEvent(
					{ $userRootStore, $userProfileStore },
					{
						event: CriteoEventTags.Cart,
						item: [{ id: itemId }]
					}
				);

				await __sendRTBHouseEvent($userProfileStore, {
					eventType: RTBHouseEventTags.Wishlist,
					offerId: itemId
				});

				await __sendTiktokEvent($userProfileStore, {
					type: TiktokEventTags.AddToCart,
					data: {
						contents: [
							{
								content_id:
									(params.job?._id && `J${params.job._id}`) ||
									(params.company?._id && `C${params.company?._id}`) ||
									undefined,
								content_name: params.job?.name || params.company?.name,
								quantity: 1
							}
						],
						content_type: 'product',
						value: 3,
						currency: 'EUR'
					}
				});

				await __sendSnapchatEvent($userProfileStore, {
					type: SnapchatEventTags.AddToCart,
					data: {
						item_ids:
							(params.job?._id && `J${params.job._id}`) ||
							(params.company?._id && `C${params.company?._id}`) ||
							undefined,
						item_category: params.job?.jobfields || params.company?.jobfields || undefined,
						number_items: 1,
						price: 3,
						currency: 'EUR'
					}
				});

				// we want to send something else to GA, but we do not want to change our custom tracking (__jobSeekerTracking)
				await __sendGAEvent('start_application' as any, {
					item_id: trackingParams.relationId,
					item_type: trackingParams.item_type,
					is_logged_in: trackingParams.is_logged_in,
					application_type: trackingParams.application_type
				});

				await __jobSeekerTracking(ctx, params, 'start-application');
				break;
			}
			case 'send_application': {
				await __sendFbEvent(
					'track',
					'jobseeker',
					FacebookEvent.Purchase,
					{
						value: 2.0,
						currency: 'EUR',
						content_name: params.job?.name || params.company?.name || undefined,
						content_type: 'product',
						content_ids:
							(params.job?.jobNr && `J${params.job.jobNr}`) ||
							(params.company?._id && `C${params.company?._id}`) ||
							undefined,
						jobfields: params.job?.jobfields || params.company?.jobfields || undefined,
						type: params.job?.type || undefined
					},
					{ eventID: params.matchId }
				);

				const trackingParams = params as ITrackUserEvents['send_application'];

				let itemId = `J${trackingParams.relationId}`;

				if (trackingParams.item_type === 'company') {
					itemId = `C${trackingParams.relationId}`;
				}

				await __sendCriteoEvent(
					{ $userRootStore, $userProfileStore },
					{
						event: CriteoEventTags.Sales,
						id: Math.floor(Math.random() * 99999999999) + 1,
						item: [{ id: itemId }]
					}
				);

				await __sendRTBHouseEvent($userProfileStore, {
					eventType: RTBHouseEventTags.Conversion,
					conversionClass: 'order',
					conversionSubClass: 'purchase',
					conversionId: Math.floor(Math.random() * 99999999999) + 1,
					offerIds: [itemId],
					conversionValue: '1' // should be a string according to https://docs.tealium.com/client-side-tags/rtb-house-tag/
				});

				await __sendTiktokEvent($userProfileStore, {
					type: TiktokEventTags.CompletePayment,
					data: {
						contents: [
							{
								content_id:
									(params.job?._id && `J${params.job._id}`) ||
									(params.company?._id && `C${params.company?._id}`) ||
									undefined,
								content_name: params.job?.name || params.company?.name,
								quantity: 1
							}
						],
						content_type: 'product',
						value: 10,
						currency: 'EUR'
					}
				});

				await __sendSnapchatEvent($userProfileStore, {
					type: SnapchatEventTags.Purchase,
					data: {
						item_ids:
							(params.job?._id && `J${params.job._id}`) ||
							(params.company?._id && `C${params.company?._id}`) ||
							undefined,
						item_category: params.job?.jobfields || params.company?.jobfields || undefined,
						number_items: 1,
						price: 10,
						currency: 'EUR'
					}
				});

				// we want to send something else to GA, but we do not want to change our custom tracking (__jobSeekerTracking)
				await __sendGAEvent('send_application' as any, {
					item_id: trackingParams.relationId,
					item_type: trackingParams.item_type,
					application_type: trackingParams.application_type
				});

				await __jobSeekerTracking(ctx, params, 'send-application');
				break;
			}
			case 'save_job': {
				const trackingParams = params as ITrackUserEvents['save_job'];
				await __sendGAEvent('save_job', trackingParams);

				await __sendFbEvent('track', 'jobseeker', FacebookEvent.AddToCart, {
					content_type: 'product',
					content_ids: [`J${trackingParams.item_id}`],
					type: trackingParams.item_type
				});

				await __sendCriteoEvent(
					{ $userRootStore, $userProfileStore },
					{
						event: CriteoEventTags.Cart,
						item: [{ id: `J${trackingParams.item_id}` }]
					}
				);

				await __sendSnapchatEvent($userProfileStore, {
					type: SnapchatEventTags.AddToCart,
					data: {
						item_ids:
							(params.job?._id && `J${params.job._id}`) ||
							(params.company?._id && `C${params.company?._id}`) ||
							undefined,
						item_category: params.job?.jobfields || params.company?.jobfields || undefined,
						number_items: 1,
						price: 3,
						currency: 'EUR'
					}
				});

				break;
			}
			default:
				// by default send GA events
				await __sendGAEvent(action, params as any);
				break;
		}
	};

export const trackGeneric =
	ctx =>
	async <ACTION extends keyof ITrackGenericEvents>(
		action: ACTION,
		params: any // ITrackGenericEvents[ACTION] // due to a typescript limitation we use any for now, see: https://github.com/microsoft/TypeScript/issues/13995
	) => {
		const { store, $userProfileStore } = ctx;
		const userEmail =
			store?.state?.user?.profile?.obj?.general?.email || $userProfileStore?.obj?.general?.email;
		const userPhone =
			store?.state?.user?.profile?.obj?.general?.phone || $userProfileStore?.obj?.general?.phone;

		if (isPWA) {
			// push everything to google tag manager
			await __sendToTagManager({
				event: action,
				mode: process.env.mode === 'b2c' ? 'jobseeker' : 'company',
				user_hashed_email: await createHashedValue(userEmail),
				user_hashed_phone: await createHashedValue(userPhone),
				...params
			});
		}
		// ALL TRACKING
		switch (action) {
			case 'register_completed': {
				const trackingParams = params as ITrackGenericEvents['register_completed'];
				await __sendGAEvent(
					params.mode === 'jobseeker' ? 'register_completed' : 'company_register_completed',
					trackingParams
				);
				await __sendFbEvent('track', params.mode, FacebookEvent.CompleteRegistration, {
					value: 5.0,
					currency: 'EUR'
				});

				// only for jobseeker send tiktok and jobseeker tracking and snapchat
				if (params.mode === 'jobseeker') {
					await __sendTiktokEvent($userProfileStore, {
						type: TiktokEventTags.CompleteRegistration,
						data: {
							value: 5,
							currency: 'EUR'
						}
					});

					await __sendSnapchatEvent($userProfileStore, {
						type: SnapchatEventTags.SignUp,
						data: {
							value: 5,
							currency: 'EUR',
							sign_up_method: 'signup'
						}
					});

					await __jobSeekerTracking(ctx, params, 'signup');
				}

				if (!isPWA) {
					// on website we send a signup even to tag manager too
					await __sendToTagManager({
						event: action,
						user_hashed_email: await createHashedValue(userEmail),
						user_hashed_phone: await createHashedValue(userPhone),
						...trackingParams
					});
				}
				break;
			}
			case 'login': {
				const trackingParams = params as ITrackGenericEvents['login'];
				await __sendGAEvent(
					params.mode === 'jobseeker' ? 'login' : 'company_login',
					trackingParams
				);
				await __sendFbEvent('trackCustom', params.mode, 'login');
				if (params.mode === 'jobseeker') {
					await __jobSeekerTracking(ctx, params, 'login');
				}
				break;
			}
			case 'cookie_banner_viewed':
			case 'cookie_banner_accepted': {
				// we want to track the cookie banner here, therefore we don't use the event queue but send the event immediately instead
				__sendGAEvent(action, params as any, true);
				break;
			}
			default:
				// by default send GA events
				await __sendGAEvent(action, params as any);
				break;
		}
	};

export default (ctx: Context, inject) => {
	const { app, store, $userProfileStore, $userRootStore } = ctx;
	const topLevelDomain = store?.state?.topLevelDomain || $userRootStore?.topLevelDomain;
	const userId = store?.state?.user?.profile?.obj?._id || $userProfileStore?.obj?._id;

	if (!process.client) {
		throw new Error('this is a client only plugin');
	}

	// import GTag (instantly for PWA, on interaction - or on load, whatever is faster - for website)
	// cordova has a native implementation of ga4 (via firebase)
	if (!process.env.cordova) {
		if (isPWA) {
			__importGTag();
		} else {
			Promise.race([
				new Promise<void>(resolve => {
					EventBus().$on('clientHasInteracted', resolve);
				}),
				/* TODO: remove as soon as we are ready to test delayed loading of gtag too */
				new Promise<void>(resolve => {
					window.onload = () => {
						resolve();
					};
				})
			]).then(() => {
				__importGTag();
			});
		}
	}

	// gets called after user has accepted cookies
	window.acceptedTracking = async () => {
		// wait till gtag is initialized, but not for cordova
		await waitForGA4Init();

		let acceptedPrivacyOptions: PrivacyOptions[] | undefined;
		const storeValue = lsTest() && localStorage.getItem('accept-cookies');

		try {
			const parsedValue = storeValue ? JSON.parse(storeValue) : undefined;
			if (parsedValue && !Array.isArray(parsedValue)) {
				throw new Error('invalid value inside of accept-cookies');
			}

			if (parsedValue.includes('essential')) {
				document.cookie = genCookie(
					'hokifyHubspotCookie',
					'true',
					365,
					`.hokify.${topLevelDomain}`
				);
			}

			acceptedPrivacyOptions = parsedValue;
		} catch (err) {
			// legacy value is "true" or "false"
			if (storeValue === 'true') {
				// if user accepted the old cookieBanner, update the values saved in localStorage
				acceptedPrivacyOptions = ['essential', 'externalYoutube', 'ads', 'stats'];
				// fix local storage value
				localStorage.setItem('accept-cookies', JSON.stringify(acceptedPrivacyOptions));
			} else {
				// something else went wrong, remove store value
				// fix local storage value
				localStorage.removeItem('accept-cookies');
			}
		}

		// google tag manager can always be loaded after user confirms cookies, due to it is essential (in b2c & b2b app)
		if (isPWA) {
			__importTagManager();
		}
		enabledTrackings.tagmanager = true;

		// Consent: stats
		if (acceptedPrivacyOptions?.includes('stats')) {
			// for cordova always enable firebase, after acceptedTracking got called
			if (process.env.cordova) {
				window.cordova?.plugins?.firebase?.analytics?.setEnabled(true);
			}

			// update analytics consent for tracking & set user_id
			if (gtagAvailable(window)) {
				window.gtag('consent', 'update', {
					analytics_storage: 'granted'
				});
				window.gtag('set', 'user_id', userId);
			}
			enabledTrackings.google = true;
		} else {
			if (gtagAvailable(window)) {
				window.gtag('consent', 'update', {
					analytics_storage: 'denied'
				});
			}
			enabledTrackings.google = false;
		}

		// Consent: ads
		if (acceptedPrivacyOptions?.includes('ads')) {
			// only load facebook if not on cordova, due to cordova uses facebook-native.js
			// but always set enableTrackings.facebook to true
			if (process.env.cordova) {
				enabledTrackings.facebook = true;
			}

			// update tiktok consent
			__importTiktokScript();
			enabledTrackings.tiktok = true;

			// update snapchat consent
			__importSnapchatScript();
			enabledTrackings.snapchat = true;

			// criteo and rtbHouse are not working on cordova ATM due to we do not have any TLD there
			if (!process.env.cordova) {
				// update facebook consent
				__importFacebookSDK();
				enabledTrackings.facebook = true;

				// update criteo consent
				if (topLevelDomain && trackingConfigDomain[topLevelDomain]) {
					__importCriteoScript(trackingConfigDomain[topLevelDomain].criteoTracker);
					enabledTrackings.criteo = true;
				} else if (process.env.NODE_ENV === 'development') {
					__importCriteoScript(trackingConfigDomain.at.criteoTracker);
					enabledTrackings.criteo = true;
				}
				// update rtbHouse consent
				if (topLevelDomain && trackingConfigDomain[topLevelDomain]) {
					__importRTBHouseScript(trackingConfigDomain[topLevelDomain].rtbHouseTracker);
					enabledTrackings.rtbhouse = true;
				} else if (process.env.NODE_ENV === 'development') {
					__importRTBHouseScript(trackingConfigDomain.at.rtbHouseTracker);
					enabledTrackings.rtbhouse = true;
				}
			}

			// update analytics consent for ads
			if (gtagAvailable(window)) {
				window.gtag('consent', 'update', {
					ad_storage: 'granted',
					ad_user_data: 'granted',
					ad_personalization: 'granted'
				});
			}
		} else {
			// if criteo tag is already loaded, but consent was denied, remove criteo script from DOM
			if (typeof window.criteo_q !== 'undefined' || window.criteo_q) {
				__unloadCriteo();
				enabledTrackings.criteo = false;
			}

			// if rtbHouse tag is already loaded, but consent was denied, remove rtbHouse script from DOM
			if (typeof window.rtbhEvents !== 'undefined' || window.rtbhEvents) {
				__unloadRTBHouse();
				enabledTrackings.rtbhouse = false;
			}

			// if tiktok tag is already loaded, but consent was denied, remove tiktok script from DOM
			if (typeof window.ttq !== 'undefined' || window.ttq) {
				__unloadTiktok();
				enabledTrackings.tiktok = false;
			}

			// if snapchat tag is already loaded, but consent was denied, remove snapchat script from DOM
			if (typeof window.snaptr !== 'undefined' || window.snaptr) {
				__unloadSnapchat();
				enabledTrackings.snapchat = false;
			}

			// if fb pixel is already loaded, but consent was denied, revoke fb consent
			if (typeof window.fbq !== 'undefined' || window.fbq) {
				window.fbq('consent', 'revoke');
				enabledTrackings.facebook = false;
			}

			if (gtagAvailable(window)) {
				window.gtag('consent', 'update', {
					ad_storage: 'denied',
					ad_user_data: 'denied',
					ad_personalization: 'denied'
				});
			}
		}

		// at this point tracking was accepted, now we go through our whole queue and track everything that was called so far
		acceptedTracking = true;
		await Promise.all(
			trackingQueue.map(async queue => {
				switch (queue.type) {
					case 'facebook':
						await __sendFbEvent(...queue.data);
						break;
					case 'criteo':
						await __sendCriteoEvent(...queue.data);
						break;
					case 'rtbhouse':
						await __sendRTBHouseEvent(...queue.data);
						break;
					case 'tiktok':
						await __sendTiktokEvent(...queue.data);
						break;
					case 'snapchat':
						await __sendSnapchatEvent(...queue.data);
						break;
					case 'google':
						await __sendGAEvent(...queue.data);
						break;
					case 'recruiterTracking':
						await __trackRecruiter(...queue.data);
						break;
					case 'tagmanager':
						await __sendToTagManager(...queue.data);
						break;
					default:
						throw new Error(`unsupported queue type: ${queue}`);
				}
			})
		);
		trackingQueue.length = 0;

		EventBus().$emit('accepted-tracking', acceptedPrivacyOptions, enabledTrackings);
	};
	app.router?.afterEach(to => {
		/* We tell Google Analytics to add a `pageview` */
		/* if it's not tracked manually with custom parameters */
		if (!__isCustomTracked(to)) {
			trackview(
				to.fullPath,
				(to.fullPath && to.fullPath.includes('/pwa/company') && 'company') || 'jobseeker'
			);
		} else if (lsTest() && localStorage.getItem('debugTracking')) {
			console.info(
				'%cDefault Page View Event is disabled.',
				'color: white; background: gray; font-size: 11px; font-weight: bold; border-radius: 5px; padding: 5px 10px; margin: 5px 0px;'
			);
		}
	});

	// only include relevant trackings
	if (process.env.appType === 'user') {
		inject('trackUserEvent', trackUser(ctx));
	} else if (process.env.appType === 'company') {
		inject('trackCompanyEvent', trackCompany(ctx));
		inject('trackRecruiterEvent', trackRecruiter(ctx));
	} else if (process.env.appType === 'website') {
		inject('trackWebsiteEvent', trackWebsite(ctx));
		inject('trackRecruiterEvent', trackRecruiter(ctx));
	}
	inject('trackGenericEvent', trackGeneric(ctx));

	if (
		ctx.query &&
		(ctx.query.utm_source ||
			ctx.query.utm_medium ||
			ctx.query.utm_campaign ||
			ctx.query.utm_content)
	) {
		// if one query paramter is occuring more than once, combine them with a "++"
		const normalizeUTMParam = input => {
			if (input && Array.isArray(input)) return input.join('++');
			return input;
		};

		const queryParams = {
			utm_source: ctx.query.utm_source,
			utm_medium: ctx.query.utm_medium,
			utm_campaign: ctx.query.utm_campaign,
			utm_content: ctx.query.utm_content,
			utm_keyword: ctx.query.utm_keyword
		};

		const getQueryParamsObject = (query: Record<string, any>): Record<string, any> => {
			const queryParamsObject: Record<string, any> = {};
			const keys = Object.keys(query);

			for (let i = 0; i < keys.length; i++) {
				const key = keys[i];
				const value = query[key];
				const nextKeysHaveValues = keys.slice(i + 1).some(k => query[k] !== undefined);

				if (Array.isArray(value)) {
					queryParamsObject[key] = value;
				} else if (value === undefined && nextKeysHaveValues) {
					queryParamsObject[key] = 'n0';
				} else {
					queryParamsObject[key] = value;
				}
			}

			return queryParamsObject;
		};
		const utmParams = getQueryParamsObject(queryParams);

		setTrackingSource(
			normalizeUTMParam(utmParams.utm_source),
			normalizeUTMParam(utmParams.utm_medium),
			normalizeUTMParam(utmParams.utm_campaign),
			normalizeUTMParam(utmParams.utm_content),
			normalizeUTMParam(utmParams.utm_keyword),
			// client only plugin, req and res are only available on server side
			undefined,
			undefined
		);
	}
};
