import { EventBus } from '@hokify/shared-components/lib/eventbus';
import { delCookie, getCookie } from '@hokify/shared-components/lib/helpers/cookie';
import { lsTest } from '@hokify/shared-components/lib/helpers/localstorage';
import type { ActionTree } from 'vuex';
import type { LoginActionPayload, SignupActionPayload } from '@hokify/login-stack/lib/types/login';
import type { IAPIReAuthResult } from '@hokify/common';
import type { IRootState } from '~/store';
import type { IUserLoginState } from './state';

const baseURL = process.server
	? process.env.APPAPI_HOST_SERVER ||
		process.env.APPAPI_HOST_BROWSER ||
		process.env.API_HOST_BROWSER
	: process.env.APPAPI_HOST_BROWSER || process.env.API_HOST_BROWSER;

export const actions: ActionTree<IUserLoginState, IRootState> = {
	async logout({ commit, dispatch }) {
		const result = await this.$axios.$post('/app-api/logout', undefined, { baseURL });
		if (result.success) {
			// remove user obj
			await dispatch('user/profile/setUser', { user: null }, { root: true });

			if (process.client) {
				const d = new Date();
				d.setTime(d.getTime() - 1000); // in the past
				const expires = `expires=${d.toUTCString()}`;
				document.cookie = `pwaReAuth=false;${expires};path=/`;

				if (lsTest()) {
					localStorage.removeItem('reAuthData');
				}
			}

			commit('sessionIdCookie', { sessionToken: false });
			EventBus().$emit('logged-out');
			// return dispatch('resetState', null, { root: true });
		}
	},
	resumeSession({ dispatch, rootState }) {
		if (process.client) {
			return dispatch('resumeSessionRequest', {
				// Try first with exisitng session, if there is any on main .com domain
				useToken: false
			}).catch(err => {
				if (rootState.login.sessionIdCookie) {
					return dispatch('resumeSessionRequest', {
						// otherwise, retry with useSessionToken true
						useToken: true
					});
				}
				throw err;
			});
		}

		// on server, we just try the one with tokens
		return dispatch('resumeSessionRequest', {
			// otherwise, retry with useSessionToken true
			useToken: true
		});
	},
	async resumeSessionRequest(
		{ dispatch, rootState },
		{ useToken }: { useToken: boolean } = { useToken: true }
	): Promise<IAPIReAuthResult> {
		const result = await this.$axios.$post(
			'/app-api/resume-session',
			{
				viaDevice: rootState.device,
				appVersion: process.env.version,
				appType: process.env.appType
			},
			{
				useSessionToken: useToken,
				withCredentials: true,
				baseURL
			}
		);

		const { loggedin, user, versionOkay } = result;
		if (loggedin) {
			await dispatch(
				'user/profile/setUser',
				{ user, versionOkay },
				{
					root: true
				}
			);
		}
		return result;
	},
	async doLogin({ dispatch, rootState }, payload: LoginActionPayload): Promise<IAPIReAuthResult> {
		payload.parameters = {
			...payload.parameters,
			viaDevice: payload.parameters.viaDevice || rootState.device,
			appVersion: process.env.version,
			appType: process.env.appType
		};

		const result = await this.$axios.$post('/app-api/login/v2', payload, { baseURL });
		const { loggedin, user, versionOkay } = result;

		if (loggedin) {
			delCookie('testerGroup');
			await dispatch(
				'user/profile/setUser',
				{ user, versionOkay },
				{
					root: true
				}
			);
		} else {
			throw result;
		}

		return result;
	},
	async doSignUp({ dispatch, rootState }, payload: SignupActionPayload): Promise<IAPIReAuthResult> {
		const cookies = process.client && window.document.cookie;
		const utm = (cookies && getCookie('utm', cookies)) || undefined;
		payload = {
			...payload,
			viaDevice: rootState.device,
			appVersion: process.env.version,
			appType: process.env.appType,
			utm,
			// add region if available
			region: rootState.topLevelDomain || undefined
		};
		const result = await this.$axios.$post('/app-api/signup', payload, { baseURL });

		if (result) {
			const { loggedin, user, versionOkay } = result;
			if (loggedin) {
				delCookie('testerGroup');
				await dispatch(
					'user/profile/setUser',
					{ user, versionOkay },
					{
						root: true
					}
				);
			}
		}
		return result;
	},
	resetState: {
		root: true,
		handler({ commit }) {
			commit('resetState');
		}
	}
};

export default actions;
