import type { ActionTree } from 'vuex';
import type { IRootState } from '~/store';
import type { IGrossNetCalculatorParams, IAPIGrossNetCalculation } from '@hokify/common';

const baseURL = process.server
	? process.env.APPAPI_HOST_SERVER ||
		process.env.APPAPI_HOST_BROWSER ||
		process.env.API_HOST_BROWSER
	: process.env.APPAPI_HOST_BROWSER || process.env.API_HOST_BROWSER;

export const actions: ActionTree<IRootState, IRootState> = {
	async sendGnc(
		_,
		payload: IGrossNetCalculatorParams & {
			jobField?: string;
			locationUrl: string;
		}
	): Promise<{ gncCalculation: IAPIGrossNetCalculation }> {
		try {
			return await this.$axios.$post('app-api/jobseeker/gnc', payload, { baseURL });
		} catch (error: any) {
			throw error.response?.data || error.response || error;
		}
	}
};

export default actions;
