import { render, staticRenderFns } from "./minimal-company.vue?vue&type=template&id=7aae925d"
import script from "./minimal-company.vue?vue&type=script&lang=ts"
export * from "./minimal-company.vue?vue&type=script&lang=ts"
import style0 from "~/assets/styles/website_company.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_webpack@4.47.0__css-loader@5.2.7_webpack@4.47.0__vue-te_eoxntyycrqiuiceqfmr2hlaiyy/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports