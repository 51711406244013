import { Component, Ref, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { LoginState } from '@hokify/login-stack/lib/types/enums';
import type { IAPIPackageVariant, IUserPrivacy } from '@hokify/common';
import type { ILoginFormData } from '@hokify/login-stack/lib/types/login';
import { getSupportPhone } from '@hokify/shared-components/lib/helpers/contact-info';
import PopupHandler from '~/components/website/PopupHandler.vue';
import { errorCodes } from '@hokify/shared-components/lib/data/errorCodes';

const loginStore = namespace('login');

export default (initialRefs: { cref?: string; uref?: string } = { cref: 'b2b' }) => {
	@Component({
		components: {
			PopupHandler
		}
	})
	class LoginBase extends Vue {
		@Ref('popupHandler') readonly popupHandler!: any;

		@loginStore.State('loggedIn') loggedIn;

		currentContingent: number | null = null;

		currentPackage: IAPIPackageVariant | null = null;

		deepLinkPath: '/addcredit' | '/addjob' | '/checkout' = '/addcredit';

		cref = initialRefs.cref || '';

		uref = initialRefs.uref || '';

		error: { title?: string; text: string } | boolean = false;

		mode = 'login';

		privacy: Array<keyof IUserPrivacy> = [];

		email = '';

		disableReset = false;

		startingState: keyof typeof LoginState = LoginState.login;

		supportPhone = getSupportPhone(this.$store.state.topLevelDomain);

		errorCodes = errorCodes;

		redirectFromBusinessPage() {
			if (this.loggedIn) {
				window.location.href = 'app/company?b2bPage=true';
			} else {
				this.$router.push('/app/company');
			}
		}

		setupHeaderForm() {
			this.privacy = ['company_register'];
			this.startingState = LoginState.signup;
			this.disableReset = true;
			// set to initialRefs.cref so that websiteHeader signup takes cref from current page
			this.openForm('login', initialRefs.cref);
		}

		setupContentForm() {
			this.privacy = ['company_digital_content'];
			this.startingState = LoginState.login;
			this.openForm('login', initialRefs.cref);
		}

		setupUserContentForm() {
			this.privacy = ['user_digital_content'];
			this.startingState = LoginState.landing;
			this.openForm('login', undefined, initialRefs.uref);
		}

		setupSignupForm() {
			if (this.loggedIn) {
				window.location.href = this.redirectPath;
			} else {
				this.disableReset = true;
				this.privacy = ['company_register'];
				this.startingState = LoginState.signup;
				this.openForm('login', initialRefs.cref);
			}
		}

		setupContactForm() {
			this.privacy = ['company_digital_content'];
			this.startingState = LoginState.signup;
			this.disableReset = true;
			this.openForm('contact', 'moreinfo');
		}

		get loginFormData(): ILoginFormData {
			return {
				email: this.email,
				cref: this.cref,
				uref: this.uref,
				startingState: this.startingState,
				privacy: this.privacy,
				disableReset: this.disableReset
			};
		}

		get redirectPath() {
			// deeplink to add job with selected package
			if (this.currentPackage?._id && this.deepLinkPath === '/addjob') {
				return `${this.deepLinkPath}/${this.currentPackage._id}`;
			}
			// deeplink to buy package WITH selected contingent
			if (this.currentPackage?._id) {
				return `${this.deepLinkPath}/${this.currentPackage._id}${
					this.currentContingent ? `/${this.currentContingent}` : ''
				}`;
			}
			// else get specified deeplinkpath or /addcredit as fallback
			return this.deepLinkPath;
		}

		currentData: ILoginFormData = this.loginFormData;

		get customHeadline() {
			switch (this.cref) {
				case 'moreinfo':
					return 'Jetzt Kontakt aufnehmen';
				case 'b2b':
				default:
					return 'Jetzt Firmenkonto erstellen';
			}
		}

		get customText() {
			switch (this.cref) {
				case 'moreinfo':
					return 'Um Sie optimal betreuen zu können, benötigen wir die folgenden Informationen von Ihnen.';
				case 'b2b':
				default:
					return 'Erstellen Sie jetzt Ihr kostenloses Firmenkonto bei hokify.';
			}
		}

		openForm(mode: string, cref?: string, uref?: string) {
			this.mode = mode;
			this.cref = cref || '';
			this.uref = uref || '';
			switch (mode) {
				case 'contact':
				case 'login':
				default:
					this.currentData = this.loginFormData;
			}

			this.$nextTick(() => {
				this.popupHandler?.handleClick();
			});
		}

		handleSendStatus(message: string, result?: any) {
			switch (this.mode) {
				case 'contact':
					this.handleModeContact(message);
					break;
				case 'login':
				case 'signup':
				default:
					this.handleModeSignup(message, result);
					break;
			}
		}

		handleModeSignup(message: string, _result?: any) {
			switch (message) {
				case 'success':
				case 'abort':
					window.location.href = this.redirectPath;
					break;
				case 'fail':
				default:
					this.$snack.danger({
						title: 'Registrierung fehlgeschlagen!',
						text: 'Bei der Registrierung ist etwas schief gelaufen.'
					});
					break;
			}
		}

		handleModeContact(message: string) {
			switch (message) {
				case 'success':
					this.$snack.success({
						title: 'Erfolg!',
						text: 'Kontaktformular erfolgreich versendet!'
					});
					break;
				case 'fail':
				default:
					this.$snack.danger({
						title: 'Fehlgeschlagen!',
						text: 'Beim Versenden des Formulars ist etwas schief gelaufen.'
					});
					break;
			}
		}
	}

	return LoginBase;
};
